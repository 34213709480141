import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import {
  redirectToOrders,
  setCurrentMenu,
} from 'src/app/redux/menus/menus.actions';
import {
  cancelOrders,
  clearOrdersPage,
  repeatOrderChanged,
  setGeneralRepeatOrders,
} from 'src/app/redux/orders/orders.actions';
import { ConsumerInfoComponent } from 'src/app/shared/components/consumer-info/consumer-info.component';
import { SimpleDialogComponent } from 'src/app/shared/components/simple-dialog/simple-dialog.component';
import { SubNavigationComponent } from 'src/app/shared/components/sub-navigation/sub-navigation.component';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { ROUTER_BACK_NAVIGATION } from 'src/app/shared/constants/misc';
import { Consumer } from 'src/app/shared/models/consumers';
import { DietDetailShort } from 'src/app/shared/models/diets';
import { Menu } from 'src/app/shared/models/menus';
import {
  ManagableItem,
  UnknownManagableItem,
} from 'src/app/shared/models/misc';
import {
  Order,
  REPEAT_OPTIONS,
  RepeatOrder,
  RepeatOrderOptionData,
} from 'src/app/shared/models/orders';
import { Terminal } from 'src/app/shared/models/user';
import { RepeatOrderComponent } from './repeat-order/repeat-order.component';
import { SelectedOrderComponent } from './selected-order/selected-order.component';

@Component({
  selector: 'win-confirm-orders',
  templateUrl: './confirm-orders.component.html',
  styleUrls: ['./confirm-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ConsumerInfoComponent,
    DatePipe,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    RepeatOrderComponent,
    SelectedOrderComponent,
    SubNavigationComponent,
    TranslocoPipe,
  ],
})
export class ConfirmOrdersComponent implements OnChanges {
  private readonly dialog = inject(MatDialog);

  private readonly activatedRouter = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  readonly consumer = input.required<Consumer>();
  readonly disabledRepeatOptions = input<REPEAT_OPTIONS[]>([]);
  readonly isManager = input.required<boolean>();
  readonly isOffline = input.required<boolean>();
  readonly isTerminal = input.required<Terminal>();
  readonly lang = input.required<InterfaceLanguage>();
  readonly manageRepeatOrders = input.required<boolean>();
  readonly menu = input.required<Menu>();
  readonly orders = input.required<Order[]>();
  readonly ordersFetched = input.required<boolean>();
  readonly repeatOrders = input.required<RepeatOrder[]>();
  readonly showBackLink = input.required<boolean>();
  readonly goBackPage = input.required<'accesses' | 'consumers' | 'orders'>();
  readonly selectedDate = input.required<string>();
  readonly showConsumerInfo = input.required<
    boolean | Terminal | ManagableItem
  >();
  readonly showConsumerInfoDetail = input.required<boolean>();
  readonly editedItem = input.required<ManagableItem | UnknownManagableItem>();

  dietsScheduled: string[] | undefined;
  goBackTranslations = ROUTER_BACK_NAVIGATION;
  hideInactiveRepeatedOrders = true;
  highlightGlobalRepeat = false;
  menuDate: string;

  constructor() {
    this.menuDate = this.activatedRouter.snapshot.params.date as string;
  }

  canCancel = false;
  datelessMenu = false;
  pulsateIcon = true;
  inactiveOrders: RepeatOrder[];
  ordersDict: Record<
    string,
    Record<string, Record<string, Record<string, Order>>>
  > = {};
  repeatOrdersDict: Record<
    string,
    Record<string, Record<string, Record<string, RepeatOrder>>>
  > = {};
  repeatOrdersEquivalentToOrders = false;
  repeatOrdersEquivalentToOrdersIsRepeatedDailyFrom = false;
  repeatOrdersAllType: 'repeat' | 'repeat_one' | 'date_range' | 'event' | null =
    null;

  ngOnChanges(changes: SimpleChanges) {
    this.handleMenuChanges(changes);
    this.handleCancelOption(changes);
    this.handleDietsScheduled(changes);
    this.handlePulsateIcon();
    this.handleOrderChanges(changes);
  }

  private handleMenuChanges(changes: SimpleChanges) {
    const menu = this.menu();
    if ('menu' in changes && menu) {
      this.datelessMenu = !menu.show_date;
      this.canCancel = this.datelessMenu ? null : menu.can_cancel;
    }
  }

  private handleCancelOption(changes: SimpleChanges) {
    if (
      this.datelessMenu &&
      this.canCancel == null &&
      this.menu() &&
      !!this.orders()?.length &&
      ('menu' in changes || 'orders' in changes)
    ) {
      this.setCancelOption();
    }
  }

  private setCancelOption() {
    const canCancelAnytime =
      this.isManager() && (!!this.editedItem() || !!this.isTerminal());
    this.canCancel = canCancelAnytime
      ? true
      : new Date(this.menuDate) >= new Date(this.menu().can_cancel_orders_from);
  }

  private handleDietsScheduled(changes: SimpleChanges) {
    const menu = this.menu();
    const editedItem = this.editedItem();
    const consumer = this.consumer();
    if (
      this.dietsScheduled === undefined &&
      menu &&
      (consumer || editedItem) &&
      ('menu' in changes || 'consumer' in changes || 'editedItem' in changes)
    ) {
      this.determineWhetherDietsScheduled(consumer || editedItem, menu);
    }
  }

  private handlePulsateIcon() {
    if (this.orders().length > 0 && this.pulsateIcon) {
      setTimeout(() => {
        this.pulsateIcon = false;
      }, 2000);
    }
  }

  private handleOrderChanges(changes: SimpleChanges) {
    const orders = this.orders();
    const repeatOrders = this.repeatOrders();
    if (
      ('repeatOrders' in changes && repeatOrders && orders) ||
      ('orders' in changes && orders && repeatOrders)
    ) {
      this.updateOrderDicts();
      this.updateInactiveOrders();
      this.updateRepeatOrders();
    }
  }

  private updateOrderDicts() {
    const repeatOrders = this.repeatOrders();
    if (repeatOrders) {
      this.repeatOrdersDict =
        this.setRepeatOrdersDict<RepeatOrder>(repeatOrders);
    }
    const orders = this.orders();
    if (orders) {
      this.ordersDict = this.setRepeatOrdersDict<Order>(orders);
    }
  }

  private updateInactiveOrders() {
    if (this.repeatOrdersDict && this.ordersDict) {
      this.setInactiveRepeatOrders();
    }
  }

  private updateRepeatOrders() {
    if (this.repeatOrders()?.length && this.orders()?.length) {
      this.validateRepeatOrders();
    }
  }

  determineWhetherDietsScheduled(
    targetObj: Consumer | ManagableItem | UnknownManagableItem | undefined,
    menu: Menu | undefined,
  ) {
    if (!this.dietsScheduled && targetObj && menu) {
      const selectedDate = this.selectedDate();
      if (selectedDate && menu.apply_scheduled_diets[selectedDate]) {
        this.dietsScheduled =
          menu.apply_scheduled_diets[selectedDate].join(',') !==
          targetObj?.diets.join(',')
            ? menu.apply_scheduled_diets_detail[selectedDate]
            : null;
      } else {
        this.dietsScheduled =
          menu.apply_diets.join(',') !== targetObj?.diets.join(',')
            ? menu.apply_diets_detail
            : null;
      }
    }
  }
  toMenus(): void {
    this.store.dispatch(clearOrdersPage());
    const goBackPage = this.goBackPage();
    if (goBackPage) {
      this.router.navigate([`manage`, goBackPage]);
    } else {
      this.addAnotherOrder();
    }
  }

  back(): void {
    this.store.dispatch(redirectToOrders({ complete: false }));
    this.store.dispatch(
      setCurrentMenu({
        menu: this.menu(),
        date: this.menuDate,
      }),
    );
  }

  cancelOrder(): void {
    const dialogRef = this.dialog.open(SimpleDialogComponent, {
      width: '600px',
      maxWidth: '100vw',
      autoFocus: false,
      data: {
        title: 'orders.confirm.cancel-dialog.title',
        text: 'orders.confirm.cancel-dialog.text',
        confirmIcon: 'delete',
        confirmText: 'orders.confirm.cancel-dialog.confirm',
        cancelText: 'orders.confirm.cancel-dialog.cancel',
        cancelable: true,
        warn: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          cancelOrders({
            editedItem: this.editedItem() as ManagableItem,
            orders: this.orders(),
          }),
        );
      }
    });
  }

  repeatChanged(
    { data, url }: { data: RepeatOrderOptionData; url: string | null },
    order: Order,
  ): void {
    this.store.dispatch(
      repeatOrderChanged({
        repeatOrderOption: data,
        order,
        url,
        menu: this.menu().identifier,
      }),
    );
  }

  repeatChangedNotOrdered(
    { data, url }: { data: RepeatOrderOptionData; url: string | null },
    repeatOrder: RepeatOrder,
  ): void {
    this.store.dispatch(
      repeatOrderChanged({
        repeatOrderOption: data,
        repeatOrder,
        url,
        menu: this.menu().identifier,
      }),
    );
  }

  globalRepeatChanged(value: RepeatOrderOptionData): void {
    this.highlightGlobalRepeat = value.option !== REPEAT_OPTIONS.DO_NO_REPEAT;
    this.repeatOrdersEquivalentToOrders =
      value.option !== REPEAT_OPTIONS.DO_NO_REPEAT;
    switch (value.option) {
      case REPEAT_OPTIONS.WEEKDAYS:
        this.repeatOrdersAllType = 'repeat_one';
        break;
      case REPEAT_OPTIONS.DATES:
        this.repeatOrdersAllType = 'date_range';
        break;
      case REPEAT_OPTIONS.REPEAT_DAILY_FROM:
        this.repeatOrdersAllType = 'event';
        break;
      case REPEAT_OPTIONS.REPEAT_DAILY:
        this.repeatOrdersAllType = 'repeat';
        break;
      default:
        this.repeatOrdersAllType = null;
    }
    this.store.dispatch(
      setGeneralRepeatOrders({ repeatOrderOptionData: value }),
    );
  }

  getSelectedDiets(selectedDietsValue: DietDetailShort[]): string {
    let diets = '';
    selectedDietsValue.forEach((element, i) => {
      diets += i > 0 ? ', ' + element.diet_name : element.diet_name;
    });
    return diets || '-';
  }

  addAnotherOrder(): void {
    this.router.navigate([`menus`], {
      queryParams: this.activatedRouter.snapshot.queryParams,
    });
  }

  setRepeatOrdersDict<T>(
    repeatedOrders: (Order | RepeatOrder)[],
  ): Record<string, Record<string, Record<string, Record<string, T>>>> {
    const result: Record<
      string,
      Record<string, Record<string, Record<string, T>>>
    > = {};
    repeatedOrders.forEach((repeatOrder) => {
      // dateless menus have the menu_name set, so we use the section_level1_baselang as the base key, else it is ignored
      const base =
        ('menu_name' in repeatOrder && repeatOrder.menu_name) ||
        ('show_date' in repeatOrder && repeatOrder.show_date) ||
        ('menu_detail' in repeatOrder && repeatOrder.menu_detail?.name)
          ? repeatOrder.section_level1_baselang
          : '';
      if (!result[base]) {
        result[base] = {};
      }
      if (!result[base][repeatOrder.section_level2_baselang]) {
        result[base][repeatOrder.section_level2_baselang] = {};
      }
      if (
        !result[base][repeatOrder.section_level2_baselang][
          repeatOrder.section_level3_baselang
        ]
      ) {
        result[base][repeatOrder.section_level2_baselang][
          repeatOrder.section_level3_baselang
        ] = {};
      }
      result[base][repeatOrder.section_level2_baselang][
        repeatOrder.section_level3_baselang
      ][repeatOrder.item_baselang] = repeatOrder as T;
    });
    return result;
  }

  toggleInactiveRepeated(): void {
    this.hideInactiveRepeatedOrders = !this.hideInactiveRepeatedOrders;
  }

  repeatAllOrdersConsumer(alreadyActive: boolean): void {
    this.highlightGlobalRepeat = !alreadyActive;
    this.repeatOrdersEquivalentToOrders = !alreadyActive;
    const repeatOrderOptionData: RepeatOrderOptionData = alreadyActive
      ? {
          option: REPEAT_OPTIONS.DO_NO_REPEAT,
          payload: {
            url: undefined,
            id: undefined,
          },
        }
      : {
          option: REPEAT_OPTIONS.REPEAT_DAILY_FROM,
          payload: this.orders()[0].date,
        };
    this.store.dispatch(
      setGeneralRepeatOrders({
        repeatOrderOptionData,
      }),
    );
  }

  setInactiveRepeatOrders(): void {
    this.inactiveOrders = this.repeatOrders().filter(
      (repeatOrder) =>
        !this.ordersDict[
          this.datelessMenu ? repeatOrder.section_level1_baselang : ''
        ]?.[repeatOrder.section_level2_baselang]?.[
          repeatOrder.section_level3_baselang
        ]?.[repeatOrder.item_baselang],
    );
  }

  validateRepeatOrders(): void {
    this.repeatOrdersEquivalentToOrders = this.orders().every(
      (order) =>
        this.repeatOrdersDict[
          this.datelessMenu ? order.section_level1_baselang : ''
        ]?.[order.section_level2_baselang]?.[order.section_level3_baselang]?.[
          order.item_baselang
        ],
    );

    if (this.repeatOrdersEquivalentToOrders) {
      const repeatOrderTypes = this.orders()
        .map((order) => {
          const repeatOrder =
            this.repeatOrdersDict[
              this.datelessMenu ? order.section_level1_baselang : ''
            ]?.[order.section_level2_baselang]?.[
              order.section_level3_baselang
            ]?.[order.item_baselang];

          if (repeatOrder?.repeat_weekdays?.length) return 'repeat_one';
          if (repeatOrder?.repeat_daily) return 'repeat';
          if (repeatOrder?.repeat_dates?.length) return 'date_range';
          if (repeatOrder?.repeat_daily_from) return 'event';

          return null;
        })
        .filter((type) => type !== null); // Filter out nulls to only consider orders with matching repeat orders

      if (repeatOrderTypes.every((type) => type === repeatOrderTypes[0])) {
        this.repeatOrdersAllType = repeatOrderTypes[0];
      } else {
        this.repeatOrdersAllType = null; // Mixed types or no repeat orders equivalent to orders
      }
    } else {
      this.repeatOrdersAllType = null; // Not all orders have a matching repeat order
    }
    this.repeatOrdersEquivalentToOrdersIsRepeatedDailyFrom =
      this.repeatOrdersAllType === 'repeat' ||
      this.repeatOrdersAllType === 'event';
  }
}
