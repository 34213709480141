import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Report } from 'src/app/shared/models/user';

export const ReportActions = createActionGroup({
  source: 'Report/API',
  events: {
    'Fetch Reports': emptyProps(),
    'Set Reports': props<{ reports: Report[] }>(),
    'Update Report': props<{ report: Update<Report> }>(),
    'Clear Reports': emptyProps(),

    'Set Loading': props<{ loading: boolean }>(),

    'Download Report': props<{ report: Report; data: any }>(),
    'View Report': props<{ report: string; params: object }>(),
  },
});
