import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { isEqual } from 'lodash-es';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SelectFieldModel } from 'src/app/shared/models/misc';
import {
  AnyAccessType,
  Consumer,
  isManagableOrderOrConsumer,
} from '../../models/consumers';
import { ManagableOrder, Order } from '../../models/orders';
import { TranslocoPipe } from '@jsverse/transloco';
import { ConsumerDataComponent } from './consumer-data/consumer-data.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { KeyValuePipe } from '@angular/common';
import { InterfaceLanguage } from '../../constants/languages';

@Component({
  selector: 'win-consumer-info',
  templateUrl: './consumer-info.component.html',
  styleUrls: ['./consumer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTooltipModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatIconModule,
    MatInputModule,
    ConsumerDataComponent,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class ConsumerInfoComponent implements OnChanges, OnInit {
  private readonly destroyRef = inject(DestroyRef);

  readonly consumer = input.required<AnyAccessType | ManagableOrder>();
  readonly currentMenuDiets = input<string[]>();
  readonly diets = input<SelectFieldModel[]>();
  readonly dietsScheduled = input<string[]>();
  readonly hasItemsSelected = input(false);
  readonly hide = input(false);
  readonly isOffline = input(false);
  readonly lang = input.required<InterfaceLanguage>();
  readonly nonRegularTranslations = input<string[]>();
  readonly orders = input<Order[]>();
  readonly showApplyOrder = input(false);
  readonly showDetail = input(false);
  readonly orderCompleted = input(false);
  readonly showDietaryCalendarLabel = input(false);
  readonly showDiets = input(false);
  readonly showSearchMenu = input(false);

  readonly applyDefaultOrder = output<{
    consumer: AnyAccessType | ManagableOrder;
    selectedDiets: string[];
  }>();

  readonly applyDiets = output<string[]>();
  readonly applySearchFilter = output<string>();
  readonly applyTranslation = output<string>();
  readonly unSelectAll = output();

  applyDietDisabled = true;
  consumerDiet = new FormControl<string[]>([]);
  hideBanner = false;
  translation = new FormControl('');
  currentDiets: string[];
  searchMenu = new FormControl('');
  filteredDiets: SelectFieldModel[];
  consumerObject: Consumer | ManagableOrder;

  ngOnChanges(changes: SimpleChanges) {
    const consumer = this.consumer();
    if ('hide' in changes && this.hide) {
      this.hideBanner = this.hide();
    }
    if (
      ('diets' in changes ||
        'consumer' in changes ||
        'currentMenuDiets' in changes) &&
      this.diets() &&
      consumer &&
      this.currentMenuDiets()
    ) {
      this.filterDiets(consumer.location);
    }
    if ('isOffline' in changes) {
      if (this.isOffline()) {
        this.consumerDiet.disable();
      } else {
        this.consumerDiet.enable();
      }
    }
    if ('consumer' in changes && consumer) {
      this.consumerObject = isManagableOrderOrConsumer(consumer)
        ? consumer
        : null;
    }
  }

  ngOnInit() {
    this.consumerDiet.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value: string[]) => {
        const selectedDiets = [...value].sort((a, b) => a.localeCompare(b));
        this.applyDietDisabled = isEqual(selectedDiets, this.currentDiets);
        this.consumerDiet.updateValueAndValidity();
      });
    this.translation.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((value: string) => {
        this.applyTranslation.emit(value);
      });
    this.searchMenu.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value: string) => {
        this.applySearchFilter.emit(value);
      });
  }

  applyOrderDiets() {
    const selectedDiets = [...this.consumerDiet.value].sort((a, b) =>
      a.localeCompare(b),
    );
    this.applyDiets.emit(selectedDiets);
    this.currentDiets = selectedDiets;
  }

  clearField(
    event: MouseEvent,
    field: 'diets' | 'searchMenu' | 'translation',
  ): void {
    event.stopPropagation();
    event.preventDefault();
    const fields = {
      searchMenu: this.searchMenu,
      translation: this.translation,
    };
    const listFields = {
      diets: this.consumerDiet,
    };
    if (field === 'diets') {
      listFields[field].setValue([]);
    } else {
      fields[field].setValue('');
    }
  }

  filterDiets(location: number | null): void {
    this.filteredDiets = this.diets().filter((t) => t.location === location);
    const currentMenuDiets = this.currentMenuDiets();
    this.consumerDiet.setValue(currentMenuDiets);
    this.currentDiets = [...currentMenuDiets].sort((a, b) =>
      a.localeCompare(b),
    );
  }

  toggleVisibility(): void {
    this.hideBanner = !this.hideBanner;
  }

  triggerDefaultOrder(): void {
    this.applyDefaultOrder.emit({
      consumer: this.consumer(),
      selectedDiets: this.consumerDiet.value,
    });
  }
}
