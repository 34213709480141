@if (loading() || !courses()) {
  <ngx-skeleton-loader
    class="skeleton"
    count="3"
    [theme]="{
      width: small ? '100%' : '310px',
      height: '120px',
      margin: small ? '10px 0' : '10px',
    }"
  ></ngx-skeleton-loader>
} @else if (courses().length) {
  <div class="swiper-controls" [class.no-controls]="courses().length <= 1">
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    <swiper-container init="false" winSwiper [config]="swiperConfig">
      @for (course of courses(); track course) {
        <swiper-slide>
          <win-course
            [course]="course"
            [isManager]="isManager()"
            [lang]="lang()"
            (deleteCourse)="deleteCourseEvent.emit($event)"
            (editCourse)="editCourseEvent.emit($event)"
          ></win-course>
        </swiper-slide>
      }
    </swiper-container>
  </div>
} @else {
  <div class="no-course">
    {{ 'menus.build-new-skills.no-courses-available' | transloco }}
  </div>
}
