import {
  ChangeDetectionStrategy,
  Component,
  Input,
  input,
  output,
} from '@angular/core';
import { LANGUAGES } from 'src/app/shared/constants/languages';
import {
  Order,
  REPEAT_OPTIONS,
  RepeatOrder,
  RepeatOrderOptionData,
} from 'src/app/shared/models/orders';
import { TranslocoPipe } from '@jsverse/transloco';
import { RepeatOrderComponent } from '../repeat-order/repeat-order.component';

@Component({
  selector: 'win-selected-order',
  templateUrl: './selected-order.component.html',
  styleUrls: ['./selected-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RepeatOrderComponent, TranslocoPipe],
})
export class SelectedOrderComponent {
  @Input() set lang(l: keyof typeof LANGUAGES) {
    this._lang = l;
  }
  get lang(): keyof typeof LANGUAGES {
    return this._lang;
  }
  _lang: keyof typeof LANGUAGES;
  readonly checkin = input<string>();
  readonly checkout = input<string>();
  readonly disabledRepeatOptions = input<REPEAT_OPTIONS[]>([]);
  readonly isDailyMenu = input(false);
  readonly isOffline = input(false);
  readonly order = input.required<Order | RepeatOrder>();
  readonly orderPrev = input.required<Order | RepeatOrder>();
  readonly repeatOrder = input<RepeatOrder>();
  readonly showRepeat = input(false);
  readonly subtleUI = input(false);

  readonly repeatChanged = output<{
    data: RepeatOrderOptionData;
    url: string | null;
  }>();
}
