import { createAction, props } from '@ngrx/store';
import { Consumer } from 'src/app/shared/models/consumers';
import { Menu } from 'src/app/shared/models/menus';
import { OfflineOrder } from 'src/app/shared/models/orders';

export const toggleOfflineMode = createAction(
  `[Offline Mode] toggle offline mode`,
  props<{ payload?: Date }>(),
);

export const setOfflineConsumer = createAction(
  `[Offline Mode] Set offline consumer`,
  props<{ payload: string }>(),
);

export const setOfflineDate = createAction(
  `[Offline Mode] Set offline date`,
  props<{ payload: Date }>(),
);

export const setOfflineOrders = createAction(
  `[Offline Mode] Set offline orders`,
  props<{ payload: Record<string, OfflineOrder> }>(),
);

export const setOfflineDataDownloading = createAction(
  `[Offline Mode] Set offline data downloading`,
  props<{ payload: boolean }>(),
);

export const setOfflineOrdersSubmitting = createAction(
  `[Offline Mode] Set offline orders submitting`,
  props<{ payload: boolean }>(),
);

export const setOfflineError = createAction(
  `[Offline Mode] Set offline error while submitting orders`,
  props<{ payload: boolean }>(),
);

export const setOfflineModeValue = createAction(
  `[Offline Mode] Set offline mode with value`,
  props<{ payload: boolean }>(),
);

export const fetchOfflineData = createAction(
  `[Offline Mode] Fetch data for the offline mode`,
  props<{ payload: Date }>(),
);

export const fetchOfflineOrders = createAction(
  `[Offline Mode] Fetch orders for the offline mode`,
  props<{ consumer: Consumer; menu: Menu }>(),
);

export const submitOfflineOrders = createAction(
  `[Offline Mode] Submit offline orders to the API`,
);
