import { BaseName } from './globals';

export interface Diet extends BaseName {
  id: number;
  identifier: string;
  location: number;
  organisation: number;
}

export interface DietDetailShort {
  identifier: string;
  diet_name: string;
  info_url: string;
}

type DietOrdering =
  | 'order,de'
  | 'order,en'
  | 'order,es'
  | 'order,fr'
  | 'order,it'
  | 'de'
  | 'en'
  | 'es'
  | 'fr'
  | 'it';

export interface DietParams {
  ordering?: DietOrdering;
  pagination?: 'off';
}

export const TEXTURE_LEVELS = [1, 2, 3, 4, 5] as const;

export const CONSISTENCY_LEVELS = [1, 2, 3, 4] as const;
