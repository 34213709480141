import { Component, input, output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatButtonModule } from '@angular/material/button';
import { FieldWithErrorsComponent } from '../../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../../shared/components/form-with-errors/form-with-errors.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'win-reset',
  templateUrl: './reset.component.html',
  styleUrls: [
    '../../../shared/styles/authentication.scss',
    './reset.component.scss',
  ],
  imports: [
    FieldWithErrorsComponent,
    FormWithErrorsComponent,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
})
export class ResetComponent {
  readonly spinnerState = input(false);
  readonly resetEmail = output<{
    email: string;
  }>();

  resetPasswordForm = new FormGroup({
    email: new FormControl('', {
      validators: [Validators.required, Validators.email],
    }),
  });

  onSubmit(): void {
    if (!this.resetPasswordForm.valid) return;
    this.resetEmail.emit({
      email: this.resetPasswordForm.value.email,
    });
  }
}
