<span class="mat-body">
  {{
    'orders.order-date'
      | transloco
        : {
            date:
              item()?.created_at
              | date
                : "EEEE, d MMMM yyyy '" +
                    ('shared.date_at' | transloco) +
                    "' HH:mm"
                : undefined
                : (globalLanguage$ | async),
          }
  }}
  <br />
  @if (
    item()?.last_modified_at &&
    (item()?.last_modified_at | date) !== (item()?.created_at | date)
  ) {
    <span class="modified">
      {{
        'orders.modified-time'
          | transloco
            : {
                date:
                  item()?.last_modified_at
                  | date
                    : "d MMMM yyyy '" +
                        ('shared.date_at' | transloco) +
                        "' HH:mm"
                    : undefined
                    : (globalLanguage$ | async),
              }
      }}
    </span>
  }
</span>
