<ng-template #repeatIcon>
  @if (!repeatOrder) {
    repeat
  } @else if (repeatOrder?.repeat_weekdays?.length) {
    repeat_one
  } @else if (repeatOrder?.repeat_daily) {
    repeat
  } @else if (repeatOrder?.repeat_dates?.length) {
    date_range
  } @else if (repeatOrder?.repeat_daily_from) {
    event
  }
</ng-template>

<div
  class="repeat-wrapper"
  [matTooltip]="'app.offline.not-available' | transloco"
  [matTooltipDisabled]="isOffline() === false"
  matTooltipClass="win-tooltip"
>
  @if (fabButton()) {
    <button
      mat-fab
      extended
      color="primary"
      class="repeat-all"
      [class.selected]="
        highlightGlobalRepeat() ||
        (repeatOrders()?.length &&
          repeatOrdersEquivalentToOrders() &&
          repeatOrdersAllType())
      "
      [matMenuTriggerFor]="menu"
      [class.active]="!!repeatOrder"
      [color]="repeatOrder ? 'primary' : ''"
      [disabled]="isOffline()"
    >
      <mat-icon>
        {{ repeatOrdersAllType() ?? 'repeat' }}
      </mat-icon>
      @if (!repeatOrdersAllType()) {
        {{ 'orders.confirm.repeat-all.repeat' | transloco }}
      } @else if (repeatOrdersAllType() === 'repeat_one') {
        {{ 'orders.confirm.repeat-all.weekly' | transloco }}
      } @else if (repeatOrdersAllType() === 'repeat') {
        {{ 'orders.confirm.repeat-all.daily' | transloco }}
      } @else if (repeatOrdersAllType() === 'date_range') {
        {{ 'orders.confirm.repeat-all.specific-date' | transloco }}
      } @else if (repeatOrdersAllType() === 'event') {
        {{ 'orders.confirm.repeat-all.daily-from' | transloco }}
      }
    </button>
  } @else {
    <button
      mat-icon-button
      [ngClass]="isOffline() ? '' : 'trigger-button'"
      [matMenuTriggerFor]="menu"
      [class.active]="!!repeatOrder"
      [color]="repeatOrder ? 'primary' : ''"
      [disabled]="isOffline()"
    >
      <mat-icon>
        <ng-container *ngTemplateOutlet="repeatIcon"></ng-container>
      </mat-icon>
    </button>
  }
</div>
<mat-menu #menu="matMenu">
  @for (option of options; track option) {
    <button
      mat-menu-item
      class="win-repeat-order-button"
      [class.selected]="option.value === selectedOption"
      (click)="optionSelected($event, option)"
    >
      <mat-icon color="primary" class="check-icon">check</mat-icon>
      {{ 'orders.confirm.repeat.' + option.title | transloco }}
      @if (option.week) {
        <span
          class="week-menu-trigger"
          #weekMenuTrigger
          [matMenuTriggerFor]="weekMenu"
        ></span>
      }
      @if (option.dates) {
        <span class="week-menu-trigger">
          <input
            matInput
            [value]="resetModel"
            [matDatepicker]="picker"
            [min]="minDate"
            [max]="maxDate"
            [placeholder]="'shared.choose-date' | transloco"
            (dateChange)="dateChanged($event)"
          />
          <mat-datepicker-toggle
            matIconPrefix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker [startAt]="initDate" [dateClass]="dateClass">
          </mat-datepicker>
        </span>
      }
      @if (option.dateFrom) {
        <span class="week-menu-trigger">
          <input
            matInput
            [value]="resetModel"
            [matDatepicker]="dateFromPicker"
            [min]="minDate"
            [max]="maxDate"
            [placeholder]="'shared.choose-date' | transloco"
            (dateChange)="dateFromChanged($event)"
          />
          <mat-datepicker-toggle
            matIconPrefix
            [for]="dateFromPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #dateFromPicker
            [startAt]="initDate"
            [dateClass]="dateClass"
          >
          </mat-datepicker>
        </span>
      }
    </button>
  }
</mat-menu>

<mat-menu #weekMenu>
  @for (day of days; track day) {
    <button
      class="win-repeat-order-button"
      [class.selected]="choosenDays[day]"
      mat-menu-item
      (click)="handleWeekday($event, day)"
    >
      <mat-icon color="primary" class="check-icon">check</mat-icon>
      {{ 'shared.weekdays.' + day | transloco }}
    </button>
  }
</mat-menu>
