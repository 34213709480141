import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  input,
  output,
} from '@angular/core';
import { Course } from 'src/app/shared/models/menus';
import { SwiperOptions } from 'swiper/types';
import { TranslocoPipe } from '@jsverse/transloco';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CourseComponent } from './course/course.component';
import { SwiperDirective } from '../../../../shared/directives/swiper.directive';

@Component({
  selector: 'win-courses-slider',
  templateUrl: './courses-slider.component.html',
  styleUrls: ['./courses-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    SwiperDirective,
    CourseComponent,
    NgxSkeletonLoaderModule,
    TranslocoPipe,
  ],
})
export class CoursesSliderComponent {
  readonly courses = input.required<Course[]>();
  readonly isManager = input.required<boolean>();
  readonly lang = input.required<string>();
  readonly loading = input.required<boolean>();
  readonly deleteCourseEvent = output<number>();
  readonly editCourseEvent = output<Course>();

  swiperConfig: SwiperOptions = {
    observer: true,
    observeParents: true,
  };

  small = window.innerWidth <= 400;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.small = window.innerWidth <= 400;
  }
}
