import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';
import { fetchMenu } from 'src/app/redux/menus/menus.actions';
import { menusFeature } from 'src/app/redux/menus/menus.state';
import {
  fetchManagableItem,
  fetchOrders,
  fetchRepeatOrders,
  setManagableItem,
} from 'src/app/redux/orders/orders.actions';
import { ordersFeature } from 'src/app/redux/orders/orders.state';
import { usersFeature } from 'src/app/redux/user/user.state';
import { ManagableItem } from 'src/app/shared/models/misc';
import {
  getOrdersParamsFromQueryParams,
  isObjectEmpty,
} from 'src/app/shared/utils.functions';

export const ordersCompleteResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _state: RouterStateSnapshot,
) => {
  const store = inject(Store);
  const router = inject(Router);

  const { menuId, date } = route.params as Record<string, string>;
  if (!menuId || !date) {
    router.navigate([`menus`]);
    return false;
  }

  const managableItem = router.getCurrentNavigation().extras
    ?.state as ManagableItem;
  const queryParams = getOrdersParamsFromQueryParams(route.queryParamMap);
  store.dispatch(setManagableItem({ item: managableItem || undefined }));
  if (isObjectEmpty(managableItem) && !isObjectEmpty(queryParams)) {
    store.dispatch(fetchManagableItem(queryParams));
  }

  return store.pipe(select(ordersFeature.selectOrderEntities)).pipe(
    withLatestFrom(
      store.pipe(select(menusFeature.selectCurrentMenu)),
      store.pipe(select(usersFeature.selectIsManager)),
    ),
    switchMap(([orders, menu, isManager]) => {
      if (!orders?.length)
        store.dispatch(fetchOrders({ menuId, date, ...queryParams }));
      if (
        (isManager && menu) ||
        (!isManager && menu?.repeated_orders_consumers)
      ) {
        store.dispatch(
          fetchRepeatOrders({
            menu_name: menu.type || !menu.date ? menu.name : '',
            manage: true,
            ...queryParams,
          }),
        );
      }
      if (!menu) {
        store.dispatch(
          fetchMenu({
            params: { menu: menuId, date, ...queryParams },
            fetchRepeatOrders: isManager,
          }),
        );
      }
      return of(true);
    }),
    take(1),
  );
};
