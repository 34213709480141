import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  input,
  viewChild,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { distinctUntilChanged } from 'rxjs';
import { ROUTER_BACK_NAVIGATION } from 'src/app/shared/constants/misc';
import { Consumer, ManagableAccess } from 'src/app/shared/models/consumers';
import { Menu } from 'src/app/shared/models/menus';
import {
  ManagableItem,
  UnknownManagableItem,
} from 'src/app/shared/models/misc';
import { ManagableOrder } from 'src/app/shared/models/orders';
import { Terminal, UnauthConsumer } from 'src/app/shared/models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { SaveStripeComponent } from '../../../shared/components/save-stripe/save-stripe.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { SubNavigationComponent } from '../../../shared/components/sub-navigation/sub-navigation.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MenuComponent } from './menu/menu.component';
import { MenusCardsComponent } from './menus-cards/menus-cards.component';
import { ConsumerInfoComponent } from '../../../shared/components/consumer-info/consumer-info.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';

@Component({
  selector: 'win-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ConsumerInfoComponent,
    MenusCardsComponent,
    MenuComponent,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    NgTemplateOutlet,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    SaveStripeComponent,
    SubNavigationComponent,
    TranslocoPipe,
    MatButtonModule,
  ],
})
export class MenusComponent implements OnChanges, OnInit {
  private readonly dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private readonly destroyRef = inject(DestroyRef);

  readonly menus = input.required<Menu[]>();
  readonly dailyMenus = input.required<Menu[]>();
  readonly specialMenus = input.required<Menu[]>();
  readonly isConsumer = input.required<boolean>();
  readonly isKdsUser = input.required<boolean>();
  readonly isServiceUser = input.required<boolean>();
  readonly isTerminal = input.required<boolean | Terminal>();
  readonly isAgent = input.required<boolean>();
  readonly isManager = input.required<boolean>();
  readonly isOffline = input.required<boolean>();
  readonly isUnauth = input.required<boolean>();
  readonly fullName = input.required<string>();
  readonly item = input.required<
    ManagableAccess | ManagableOrder | UnknownManagableItem
  >();
  readonly offlineDate = input.required<string>();
  readonly lang = input.required<InterfaceLanguage>();
  readonly staffOrdertaking = input.required<boolean>();
  readonly showThirdMenuCard = input.required<boolean>();
  readonly goBackPage = input.required<'accesses' | 'consumers' | 'orders'>();
  readonly consumer = input.required<Consumer>();
  readonly unauthConsumer = input.required<UnauthConsumer>();
  readonly showConsumerInfo = input.required<
    boolean | Terminal | ManagableItem
  >();
  readonly showConsumerInfoDetail = input.required<boolean>();

  readonly logout = output<void>();
  readonly selectMenu = output<Menu>();
  readonly selectMenuShowDatepicker = output<Menu>();
  readonly goBack = output<void>();
  readonly changeCheckoutDate = output<{
    data: {
      checkout: string;
    };
    id: number;
    url: string;
  }>();

  minDate = new Date();
  initDate = new Date();
  isPastCheckout = false;
  checkoutDate = new FormControl('');
  small = window.innerWidth <= 400;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.small = window.innerWidth <= 400;
  }

  readonly menusRef = viewChild('menusRef', { read: ElementRef });

  goBackTranslations = ROUTER_BACK_NAVIGATION;

  ngOnChanges(changes: SimpleChanges): void {
    const item = this.item();
    const isManager = this.isManager();
    if (
      ('item' in changes && item && isManager) ||
      ('isManager' in changes && isManager && item)
    ) {
      this.isPastCheckout = this.checkIsPastCheckout();
    }
  }

  ngOnInit(): void {
    this.checkoutDate.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((date) =>
        this.changeCheckoutDate.emit({
          data: {
            checkout: this.dateAdapter.format(new Date(date), 'yyyy-MM-dd'),
          },
          id: this.item().id,
          url: this.item().url,
        }),
      );
  }

  scrollToMenus(): void {
    (this.menusRef()?.nativeElement as HTMLElement).scrollIntoView({
      behavior: 'smooth',
    });
  }

  checkIsPastCheckout(): boolean {
    const item = this.item();
    const checkoutDate = item?.checkout;
    if (!checkoutDate) return undefined;
    const checkout = new Date(checkoutDate);

    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const offset = date.getTimezoneOffset();
    const today = new Date(date.getTime() - offset * 60 * 1000);
    const result = today.getTime() > checkout.getTime();
    if (result) {
      this.checkoutDate.patchValue(item?.checkout, { emitEvent: false });
    }
    return result;
  }
}
