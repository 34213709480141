import { provideStoreDevtools } from '@ngrx/store-devtools';

export const environment = {
  production: false,
  apiUrl: 'https://devorders.menutech.com',
  mealPlanningApiUrl: 'https://devapi.menutech.com',
  kdsUrl: 'https://chef-staging.menutech.com/fired',
  serviceUrl: 'https://service-staging.menutech.com/reservations',
  providers: [
    provideStoreDevtools({
      maxAge: 25,
      connectInZone: true,
    }),
  ],
};
