import { Component, inject } from '@angular/core';
import { OfflineModeService } from 'src/app/shared/services/offline-mode/offline-mode.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe } from '@angular/common';
import {
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';

@Component({
  selector: 'win-offline-mode-confirm-modal',
  templateUrl: './offline-mode-confirm-modal.component.html',
  styleUrls: ['./offline-mode-confirm-modal.component.scss'],
  imports: [
    AsyncPipe,
    FormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
})
export class OfflineModeConfirmModalComponent {
  private readonly offlineModeService = inject(OfflineModeService);

  minDate: Date;
  initDate = new Date();
  isOffline = this.offlineModeService.isOffline();
  selectedDate: Date | null;

  constructor() {
    this.minDate = new Date();
  }
}
