import { createFeature, createReducer, on } from '@ngrx/store';
import { Diet } from '../../shared/models/diets';
import { setDiets } from './diets.actions';

interface DietsState {
  items: Diet[];
}

const initialState: DietsState = {
  items: null,
};

const reducer = createReducer(
  initialState,
  on(setDiets, (state, { payload: diets }) => ({
    ...state,
    items: diets,
  })),
);

export const dietsFeature = createFeature({
  name: 'diets',
  reducer,
});
