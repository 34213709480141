<button
  mat-button
  class="button"
  color="primary"
  [class.locked]="menu()?.can_order === false"
  [disabled]="disabled() || menu()?.can_order === false"
  (click)="btnClicked.emit()"
  [matTooltip]="
    disabled() || menu()?.can_order === false
      ? ('menus.tooltip' | transloco)
      : ''
  "
  matTooltipPosition="above"
  matTooltipClass="win-tooltip"
>
  @if (!arrowForward()) {
    <mat-icon class="arrow">arrow_back</mat-icon>
  }
  <span class="text">
    {{
      (arrowForward()
        ? 'orders.save-and-go-next'
        : 'orders.save-and-go-previous'
      ) | transloco
    }}
  </span>
  <mat-icon class="lock-icon">lock</mat-icon>
  @if (sameDay()) {
    {{ menu()?.name }}
  } @else {
    {{ menu()?.date | date: 'EEEE' : null : lang() }}
  }
  @if (arrowForward()) {
    <mat-icon class="arrow">arrow_forward</mat-icon>
  }
</button>
