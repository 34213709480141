import {
  Component,
  DestroyRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  input,
  viewChild,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { isNil, omitBy } from 'lodash-es';
import {
  NgxOtpInputComponent,
  NgxOtpInputComponentOptions,
} from 'ngx-otp-input';
import { of } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';
import { clearFormErrors } from 'src/app/redux/global/global.actions';
import { setOfflineConsumer } from 'src/app/redux/offline-mode/offline-mode.actions';
import { NON_FIELD_ERRORS } from 'src/app/shared/directives/server-form-error.directive';
import {
  RoomConsumer,
  PrivacyLevel,
  TerminalType,
} from 'src/app/shared/models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FieldWithErrorsComponent } from '../../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../../shared/components/form-with-errors/form-with-errors.component';
import { MatCardModule } from '@angular/material/card';
import { globalFeature } from 'src/app/redux/global/global.state';

@Component({
  selector: 'win-terminal-login',
  templateUrl: './terminal-login.component.html',
  styleUrls: ['./terminal-login.component.scss'],
  imports: [
    MatCardModule,
    FormWithErrorsComponent,
    ReactiveFormsModule,
    FieldWithErrorsComponent,
    MatButtonModule,
    MatIconModule,
    NgxOtpInputComponent,
    TranslocoPipe,
  ],
})
export class TerminalLoginComponent implements OnChanges, OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);

  readonly loading = input.required<boolean>();
  readonly roomDetailsLoading = input.required<boolean>();
  readonly rfidEnabled = input.required<boolean>();
  readonly terminalType = input.required<TerminalType>();
  readonly terminalPrivacy = input.required<PrivacyLevel>();
  readonly roomDetails = input.required<
    | RoomConsumer[]
    | {
        message: string;
      }
  >();
  readonly isManager = input.required<boolean>();
  readonly aggregatedOrderTakingEnabled = input.required<boolean>();

  readonly consumerNameTerminalLogin = output<{
    room?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
  }>();
  readonly consumerTerminalLogin = output<{
    uuid: string;
  }>();
  readonly terminalLogin = output<{
    rfid?: string;
    room?: string;
    formId: string;
  }>();
  readonly validateRoom = output<{
    room: string;
    formId: string;
  }>();

  consumerForm = new FormGroup({
    room: new FormControl<string>(''),
    name: new FormControl<string>(null),
    first_name: new FormControl<string>(null),
    last_name: new FormControl<string>(null),
  });
  readonly consumerFormId = 'consumer-form';
  get controlName(): string {
    return this.rfidEnabled() ? 'rfid' : 'room';
  }
  otpInputConfig: NgxOtpInputComponentOptions = {
    otpLength: 3,
    inputMode: 'text',
    regexp: /^[a-zA-ZäöüßÄÖÜẞ\u00C0-\u017F]+$/,
  };
  roomDetailsList: RoomConsumer[];
  showCodeInput = false;
  showLastName = false;
  terminalForm = new FormGroup({
    rfid: new FormControl<string>(null, {
      validators: [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(12),
      ],
    }),
    room: new FormControl<string>(null, { validators: [Validators.required] }),
  });
  readonly terminalFormId = 'terminal-login';
  userIsTyping = false;

  readonly codeInputFirstName =
    viewChild<NgxOtpInputComponent>('firstNameCodeInput');
  readonly codeInputLastName =
    viewChild<NgxOtpInputComponent>('lastNameCodeInput');
  readonly codeInputName = viewChild<NgxOtpInputComponent>('nameCodeInput');

  ngOnChanges(changes: SimpleChanges) {
    if ('isManager' in changes) {
      const validators = this.isManager() ? [Validators.required] : [];
      this.consumerForm.controls.room.setValidators(validators);
      this.consumerForm.controls.room.updateValueAndValidity();
    }
    const rfidEnabled = this.rfidEnabled();
    if ('rfidEnabled' in changes && rfidEnabled !== undefined) {
      if (rfidEnabled) {
        this.terminalForm.removeControl('room');
      } else {
        this.terminalForm.removeControl('rfid');
      }
      this.terminalForm.updateValueAndValidity();
    }
    if (
      'roomDetails' in changes ||
      'isManager' in changes ||
      'terminalPrivacy' in changes
    ) {
      const isManager = this.isManager();
      const roomDetails = this.roomDetails();
      const terminalPrivacy = this.terminalPrivacy();
      if (
        isManager &&
        Array.isArray(roomDetails) &&
        roomDetails?.length > 0 &&
        terminalPrivacy !== PrivacyLevel.ANONYMOUS
      ) {
        this.roomDetailsList = roomDetails;
      } else if (!isManager || terminalPrivacy === PrivacyLevel.ANONYMOUS) {
        this.showCodeInput = true;
      }
    }
  }

  ngOnInit(): void {
    this.consumerForm.controls.room.valueChanges
      .pipe(
        tap(() => (this.userIsTyping = true)),
        distinctUntilChanged(),
        debounceTime(400),
        filter((r) => !!r),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((room) => {
        this.userIsTyping = false;
        this.validateRoom.emit({
          room: room,
          formId: this.consumerFormId,
        });
      });
    this.store
      .pipe(select(globalFeature.selectFormErrors))
      .pipe(
        filter((error) => !!error?.[NON_FIELD_ERRORS]),
        tap(() => {
          const codeInputFirstName = this.codeInputFirstName();
          const codeInputLastName = this.codeInputLastName();
          const codeInputName = this.codeInputName();
          if (codeInputFirstName || codeInputLastName || codeInputName) {
            if (codeInputFirstName) codeInputFirstName?.reset();
            if (codeInputLastName) codeInputLastName?.reset();
            if (codeInputName) codeInputName?.reset();
          } else {
            this.roomDetailsList = undefined;
          }
          if (this.terminalForm)
            this.terminalForm.reset({}, { emitEvent: false });
        }),
        switchMap(() => of(null).pipe(delay(10000))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.terminalForm.updateValueAndValidity();
        this.consumerForm.updateValueAndValidity();
        this.store.dispatch(clearFormErrors({ payload: NON_FIELD_ERRORS }));
      });
  }

  triggerTerminalLogin(): void {
    if (
      !this.terminalForm.valid ||
      !this.terminalForm.get(this.controlName)?.value
    )
      return;
    this.terminalLogin.emit({
      [this.controlName]: this.terminalForm.get(this.controlName)
        ?.value as string,
      formId: this.terminalFormId,
    });
  }

  initConsumerTerminalLogin(uuid: string): void {
    this.store.dispatch(setOfflineConsumer({ payload: uuid }));
    this.consumerTerminalLogin.emit({ uuid });
  }

  triggerConsumerTerminalLogin(): void {
    const consumerTerminalName = {
      room: this.consumerForm.controls.room.value,
      name: this.consumerForm.controls.name.value,
      first_name: this.consumerForm.controls.first_name.value,
      last_name: this.consumerForm.controls.last_name.value,
    };
    this.consumerNameTerminalLogin.emit(omitBy(consumerTerminalName, isNil));
  }

  onNameCodeCompleted(code: string): void {
    this.consumerForm.controls.name.setValue(code);
    this.triggerConsumerTerminalLogin();
  }

  onCodeCompleted(code: string, name: string): void {
    if (name === 'first_name') {
      this.consumerForm.controls.first_name.setValue(code);
      setTimeout(() => {
        this.showLastName = true;
      }, 200);
    }
    if (name === 'last_name') {
      this.consumerForm.controls.last_name.setValue(code);
    }
    if (
      this.consumerForm.controls.first_name.value?.length &&
      this.consumerForm.controls.last_name.value?.length
    ) {
      this.triggerConsumerTerminalLogin();
    }
  }

  clearRoom(): void {
    this.consumerForm.controls.room.reset();
  }
}
