<div class="title">
  <h2 class="mat-h2">
    {{ 'menus.build-new-skills.title' | transloco }}
  </h2>
  @if (isManager()) {
    <button
      mat-button
      routerLink="/manage/course"
      class="mat-h2 mat-caption create-new-course"
      (click)="addNewCourse.emit()"
    >
      {{ 'menus.build-new-skills.create-new-course' | transloco }}
    </button>
  }
</div>
<win-courses-slider
  class="courses-slider"
  [courses]="courses()"
  [isManager]="isManager()"
  [lang]="lang()"
  [loading]="loading()"
  (deleteCourseEvent)="deleteCourse.emit($event)"
  (editCourseEvent)="editCourse.emit($event)"
>
</win-courses-slider>
