import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { setOfflineOrders } from 'src/app/redux/offline-mode/offline-mode.actions';
import { setOrders } from 'src/app/redux/orders/orders.actions';
import { Menu } from '../../models/menus';
import { OfflineOrder } from '../../models/orders';

@Injectable({ providedIn: 'root' })
export class OfflineOrdersService {
  private readonly store = inject(Store);
  private readonly KEY = 'created-orders';

  getOrders(): Record<string, OfflineOrder> {
    const orders = JSON.parse(localStorage.getItem(this.KEY) || '{}') as Record<
      string,
      OfflineOrder
    >;
    this.store.dispatch(setOfflineOrders({ payload: orders }));
    return orders;
  }

  setOrders(orders: Record<string, OfflineOrder>): void {
    localStorage.setItem(this.KEY, JSON.stringify(orders));
    this.store.dispatch(setOfflineOrders({ payload: orders }));
  }

  addOrder(order: OfflineOrder): void {
    const existingOrders = this.getOrders();
    existingOrders[order.consumer + '--' + order.menu] = order;
    this.setOrders(existingOrders);
    this.addOrderToMenu(order.consumer, order.menu);
    this.store.dispatch(setOrders({ orders: order.data.items }));
  }

  deleteOrder(consumer: string): void {
    const orders = this.getOrders();
    delete orders[consumer];
    this.setOrders(orders);
  }

  getOrderCount(): number {
    const orders = this.getOrders();
    return Object.keys(orders).length;
  }

  private addOrderToMenu(consumerRfid: string, menuId: string): void {
    const menus = JSON.parse(localStorage.getItem('menus')) as Record<
      string,
      { results: Menu[] }
    >;
    menus[consumerRfid].results = menus[consumerRfid].results.map((menu) => {
      if (menu.identifier === menuId) {
        menu.has_orders = true;
      }
      return menu;
    });

    localStorage.setItem('menus', JSON.stringify(menus));
  }
}
