import { Component, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { selectQueryParams } from 'src/app/redux/router/router.selectors';
import { AsyncPipe } from '@angular/common';
import { ConfirmOrdersComponent } from './confirm-orders/confirm-orders.component';
import { globalFeature } from 'src/app/redux/global/global.state';
import { offlineModeFeature } from 'src/app/redux/offline-mode/offline-mode.state';
import { menusFeature } from 'src/app/redux/menus/menus.state';
import { ordersFeature } from 'src/app/redux/orders/orders.state';
import { usersFeature } from 'src/app/redux/user/user.state';

@Component({
  selector: 'win-orders-complete',
  templateUrl: './orders-complete.container.html',
  styleUrls: ['./orders-complete.container.scss'],
  imports: [ConfirmOrdersComponent, AsyncPipe],
})
export class OrdersCompleteContainer {
  private readonly store = inject(Store);

  backQueryParam$ = this.store.pipe(select(selectQueryParams)).pipe(
    map((params) => params?.back as 'accesses' | 'consumers' | 'orders'),
    filter((back) => !!back),
  );
  consumer$ = this.store.pipe(select(usersFeature.selectCurrentConsumer));
  globalLanguage$ = this.store.pipe(select(globalFeature.selectLanguage));
  isManager$ = this.store.pipe(select(usersFeature.selectIsManager));
  isOffline$ = this.store.pipe(select(offlineModeFeature.selectValue));
  isTerminal$ = this.store.pipe(select(usersFeature.selectTerminal));
  manageRepeatOrders$ = this.store.pipe(
    select(ordersFeature.selectManageRepeatOrders),
  );
  managableItem$ = this.store.pipe(select(ordersFeature.selectRouterExtras));
  menu$ = this.store.pipe(select(menusFeature.selectCurrentMenu));
  orders$ = this.store.pipe(select(ordersFeature.selectOrderEntities));
  ordersFetched$ = this.store.pipe(select(ordersFeature.selectOrdersFetched));
  repeatOrders$ = this.store.pipe(
    select(ordersFeature.selectRepeatOrdersManage),
  );
  selectedDate$ = this.store.pipe(select(menusFeature.selectSelectedDate));
  selectShowConsumerInfo$ = this.store.pipe(
    select(ordersFeature.selectShowConsumerInfo),
  );
  selectShowConsumerInfoDetail$ = this.store.pipe(
    select(ordersFeature.selectShowConsumerInfoDetail),
  );
  showBackLink$ = this.store
    .pipe(select(menusFeature.selectCurrentMenu))
    .pipe(map((menu) => menu && !(menu?.has_orders && !menu?.can_order)));
  selectDisabledRepeatOptions$ = this.store.pipe(
    select(usersFeature.selectDisabledRepeatOptions),
  );
}
