import { HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import {
  Actions,
  ROOT_EFFECTS_INIT,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { EMPTY, Subject, merge } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AuthenticationServiceBuilder } from 'src/app/shared/services/authentication/authentication.builder';
import { NON_FIELD_ERRORS } from 'src/app/shared/directives/server-form-error.directive';
import { Consumer } from 'src/app/shared/models/consumers';
import { FormErrors } from 'src/app/shared/models/globals';
import { OrganisationFeatures } from 'src/app/shared/models/user';
import { extractFormErrors } from 'src/app/shared/utils.functions';

import { AutoLogoutDialog } from '../../shared/components/auto-logout/auto-logout.dialog';
import { VerifyPasswordDialog } from '../../shared/components/verify-password/verify-password.dialog';
import {
  LoginSuccess,
  PasswordVerificationForm,
  ResetSuccess,
} from '../../shared/models/authentication';
import { IdleService } from '../../shared/services/idle/idle.service';
import { UtilsService } from '../../shared/services/utils.service';
import { resetMenusReducer } from '../menus/menus.actions';
import { clearOrdersPage } from '../orders/orders.actions';
import { selectRouteParams } from '../router/router.selectors';
import {
  disconnectWebSocket,
  fetchConsumerOrganisation,
} from '../user/user.actions';
import { SimpleAuthenticationParams } from './../../shared/models/authentication';
import {
  clearFormErrors,
  handleHttpError,
  setFormErrors,
  showSnackbarMessage,
} from './../global/global.actions';
import {
  getUser,
  getUserAndRedirect,
  removeUserLocally,
  setConsumer,
  setRoomDetail,
  setUser,
} from './../user/user.actions';
import * as AuthenticationActions from './authentication.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { usersFeature } from '../user/user.state';
import { authenticationFeature } from './authentication.state';

@Injectable()
export class AuthenticationEffects {
  private readonly actions$ = inject(Actions);
  private readonly authServiceBuilder = inject(AuthenticationServiceBuilder);
  private readonly destroyRef = inject(DestroyRef);
  private readonly dialog = inject(MatDialog);
  private readonly idleService = inject(IdleService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly transloco = inject(TranslocoService);
  private readonly utilsService = inject(UtilsService);

  afterEffectsInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      mergeMap(() => {
        const isLogged = !!this.authServiceBuilder.getService().getToken();
        return [
          AuthenticationActions.setLogin({ isLogged }),
          ...(isLogged ? [getUser()] : []),
        ];
      }),
      catchError(() => EMPTY),
    ),
  );

  setAuthKeyAndId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.setAuthKeyAndId),
      switchMap(() => [
        AuthenticationActions.setLogin({ isLogged: true }),
        fetchConsumerOrganisation(),
      ]),
    ),
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.login),
      tap(() => {
        localStorage.clear();
        this.store.dispatch(
          AuthenticationActions.setLoginButtonState({ login_spinner: true }),
        );
      }),
      switchMap(({ email, password, formId }) =>
        this.authServiceBuilder
          .getService()
          .login({ email, password })
          .pipe(
            tap((successData: LoginSuccess) => {
              this.authServiceBuilder.getService().saveToken(successData);
            }),
            mergeMap(() => [
              AuthenticationActions.setLoginButtonState({
                login_spinner: false,
              }),
              AuthenticationActions.setLogin({ isLogged: true }),
              getUserAndRedirect({ route: ['menus'] }),
            ]),
            catchError((error: unknown) => [
              AuthenticationActions.setLoginButtonState({
                login_spinner: false,
              }),
              handleHttpError({ error, formId }),
            ]),
          ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.logout),
      withLatestFrom(
        this.store.pipe(
          select(authenticationFeature.selectIsLoggedIntoTerminal),
        ),
        this.store.pipe(select(usersFeature.selectTerminal)),
      ),
      switchMap(([, loggedTerminal, terminal]) => {
        this.destroyed$.next();
        this.destroyed$.complete();
        if (loggedTerminal) {
          return [AuthenticationActions.logoutPartially()];
        } else if (terminal?.protected) {
          this.utilsService.enableAutofocus.next(false);
          const dialogRef = this.dialog.open(VerifyPasswordDialog, {
            data: {
              key: `logout.confirm-logout-dialog`,
              verifyCallback: (d: PasswordVerificationForm) =>
                this.store.dispatch(
                  AuthenticationActions.verifyPasswordAndLogout(d),
                ),
            },
            disableClose: true,
            width: '600px',
          });
          dialogRef.afterOpened().subscribe(() => {
            setTimeout(() => {
              dialogRef.close();
            }, 20000);
          });
          return EMPTY;
        } else {
          return [AuthenticationActions.logoutCompletely()];
        }
      }),
      catchError(() => EMPTY),
    ),
  );

  logoutPartially$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.logoutPartially),
      withLatestFrom(this.store.pipe(select(usersFeature.selectCurrentUser))),
      switchMap(([, user]) =>
        this.authServiceBuilder
          .getService()
          .logoutFromRoom(user?.id)
          .pipe(
            mergeMap(() => {
              this.router.navigate(['login']);
              return [
                AuthenticationActions.setLoggedIntoTerminal({
                  isLoggedIntoTerminal: false,
                }),
                setConsumer({ payload: null }),
                AuthenticationActions.cleanStates(),
              ];
            }),
          ),
      ),
      catchError((error: unknown) => [handleHttpError({ error })]),
    ),
  );

  terminalLoginOrLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthenticationActions.setLoggedIntoTerminal),
        withLatestFrom(this.store.pipe(select(usersFeature.selectTerminal))),
        switchMap(([action, terminal]) => {
          if (action?.isLoggedIntoTerminal && terminal?.protected) {
            // terminal login
            this.idleService.startWatching();
            let dialogRef: MatDialogRef<AutoLogoutDialog>;

            const onTimeout$ = this.idleService.onTimeout().pipe(
              tap(() => {
                this.idleService.stopTimer();
                this.idleService.stopWatching();
                dialogRef?.close(false);
              }),
              takeUntilDestroyed(this.destroyRef),
            );

            const onTimerStart$ = this.idleService.onTimerStart().pipe(
              tap((timer) => {
                if (!dialogRef?.componentInstance) {
                  dialogRef = this.dialog.open(AutoLogoutDialog, {
                    disableClose: true,
                    autoFocus: false,
                    width: '600px',
                  });
                }
                dialogRef.componentInstance.time = timer;
              }),
              switchMap(() => dialogRef.afterClosed()),
              tap((thisState) => {
                if (thisState) {
                  this.idleService.resetTimer();
                } else {
                  this.idleService.stopWatching();
                  this.idleService.stopTimer();
                  this.store.dispatch(AuthenticationActions.logout());
                }
              }),
              takeUntilDestroyed(this.destroyRef),
            );

            return merge(onTimeout$, onTimerStart$);
          } else {
            // logout
            this.idleService.stopTimer();
            this.idleService.stopWatching();
            return EMPTY;
          }
        }),
      ),
    { dispatch: false },
  );

  logoutCompletely$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.logoutCompletely),
      switchMap(() => {
        this.authServiceBuilder.getService().removeToken();
        return [
          AuthenticationActions.setLoggedIntoTerminal({
            isLoggedIntoTerminal: false,
          }),
          AuthenticationActions.setLogin({ isLogged: false }),
          setUser({ payload: undefined }),
          AuthenticationActions.cleanStates(),
          removeUserLocally(),
          disconnectWebSocket(),
          AuthenticationActions.redirectAfterLoggingOut(),
        ];
      }),
      catchError(() => EMPTY),
    ),
  );

  redirectAfterLoggingOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthenticationActions.redirectAfterLoggingOut),
        switchMap(() => {
          this.dialog.closeAll();
          return this.router.navigate(['login']);
        }),
      ),
    {
      dispatch: false,
    },
  );

  roomLoginLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.roomLogin),
      switchMap(() => [AuthenticationActions.roomLoginBar({ payload: true })]),
    ),
  );

  roomLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.roomLogin),
      withLatestFrom(this.store.pipe(select(usersFeature.selectCurrentUser))),
      switchMap(([{ rfid, room, formId }, { id: uid }]) =>
        this.authServiceBuilder
          .getService()
          .loginToRoom(uid, { rfid, room })
          .pipe(
            mergeMap((consumer: Consumer) => [
              AuthenticationActions.setLoggedIntoTerminal({
                isLoggedIntoTerminal: true,
              }),
              AuthenticationActions.roomLoginBar({ payload: false }),
              AuthenticationActions.loggedIntoTerminal(),
              setConsumer({ payload: consumer }),
            ]),
            catchError((error: unknown) => {
              if (
                error instanceof HttpErrorResponse &&
                error.error.error === this.messageNotCheckedInKey
              ) {
                error.error.error = this.messageNotCheckedIn;
              } else if (
                error instanceof HttpErrorResponse &&
                error.error.error === this.messageNotEnabledKey
              ) {
                error.error.error = this.messageNotEnabled;
              }
              return [
                handleHttpError({ error, formId }),
                AuthenticationActions.roomLoginBar({ payload: false }),
              ];
            }),
          ),
      ),
    ),
  );

  consumerTerminalLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.consumerTerminalLogin),
      withLatestFrom(this.store.pipe(select(usersFeature.selectCurrentUser))),
      switchMap(([{ uuid }, { id: uid }]) =>
        this.authServiceBuilder
          .getService()
          .consumerTerminalLogin(uid, { uuid })
          .pipe(
            mergeMap((consumer: Consumer) => [
              AuthenticationActions.setLoggedIntoTerminal({
                isLoggedIntoTerminal: true,
              }),
              AuthenticationActions.loggedIntoTerminal(),
              setConsumer({ payload: consumer }),
              setRoomDetail({ payload: [] }),
            ]),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  retrieveUuid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.retrieveUuid),
      switchMap(({ consumerName, key, roomNo }) =>
        this.authServiceBuilder
          .getService()
          .retrieveUuid(consumerName, key, roomNo)
          .pipe(
            mergeMap((consumerUuid) => {
              const queryParams: Params = {
                key: key,
                auth_uuid: consumerUuid.uuid,
              };
              const payload: SimpleAuthenticationParams = {
                key: key,
                id: {
                  key: 'auth_uuid',
                  value: consumerUuid.uuid,
                },
              };
              this.store
                .select(usersFeature.selectSimpleConsumer)
                .pipe(
                  filter((v) => !!v),
                  take(1),
                  takeUntilDestroyed(this.destroyRef),
                )
                .subscribe(() => {
                  this.router.navigate(['menus'], { queryParams });
                });
              return [
                AuthenticationActions.setUnauthSpinner({ value: true }),
                AuthenticationActions.setAuthKeyAndId({ payload }),
              ];
            }),
            catchError((error: unknown) => [
              AuthenticationActions.setUnauthSpinner({ value: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  validateKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.validateKey),
      switchMap(({ key }) => {
        this.store.dispatch(
          AuthenticationActions.setUnauthSpinner({ value: true }),
        );
        this.store.dispatch(
          AuthenticationActions.setUnauthInvalidUrlMessage({ show: false }),
        );
        return this.authServiceBuilder
          .getService()
          .validateKey(key)
          .pipe(
            mergeMap((res) => {
              const aggregatedOrdertaking = res.enabled_features.includes(
                OrganisationFeatures.aggregated_ordertaking,
              );
              return [
                AuthenticationActions.setUnauthAggregatedOrderFeature({
                  enable: aggregatedOrdertaking,
                }),
                AuthenticationActions.setUnauthSpinner({ value: false }),
              ];
            }),
            catchError((error: unknown) => {
              if (error instanceof HttpErrorResponse && error.status === 401) {
                this.store.dispatch(
                  AuthenticationActions.setUnauthInvalidUrlMessage({
                    show: true,
                  }),
                );
              } else {
                this.store.dispatch(handleHttpError({ error }));
              }
              return [AuthenticationActions.setUnauthSpinner({ value: false })];
            }),
          );
      }),
    ),
  );

  consumerNameTerminalLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.consumerNameTerminalLogin),
      withLatestFrom(this.store.pipe(select(usersFeature.selectCurrentUser))),
      switchMap(([{ room, name, first_name, last_name }, { id: uid }]) =>
        this.authServiceBuilder
          .getService()
          .consumerNameTerminalLogin(uid, { room, name, first_name, last_name })
          .pipe(
            mergeMap((consumer: Consumer) => [
              AuthenticationActions.setLoggedIntoTerminal({
                isLoggedIntoTerminal: true,
              }),
              AuthenticationActions.loggedIntoTerminal(),
              setConsumer({ payload: consumer }),
            ]),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  roomDetailsLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.validateRoom),
      switchMap(() => [
        AuthenticationActions.roomDetailsLoadingBar({ payload: true }),
      ]),
    ),
  );

  validateRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.validateRoom),
      withLatestFrom(this.store.pipe(select(usersFeature.selectCurrentUser))),
      switchMap(([{ room, formId }, { id: uid }]) =>
        this.authServiceBuilder
          .getService()
          .validateRoom(uid, { room })
          .pipe(
            mergeMap((roomDetail) => [
              AuthenticationActions.roomDetailsLoadingBar({ payload: false }),
              setRoomDetail({ payload: roomDetail }),
            ]),
            catchError((error: unknown) => {
              if (
                error instanceof HttpErrorResponse &&
                error.error.error === this.messageNotCheckedInKey
              ) {
                error.error.error = this.messageNotCheckedIn;
              } else if (
                error instanceof HttpErrorResponse &&
                error.error.error === this.messageNotEnabledKey
              ) {
                error.error.error = this.messageNotEnabled;
              }
              return [
                handleHttpError({ error, formId }),
                AuthenticationActions.roomDetailsLoadingBar({ payload: false }),
              ];
            }),
          ),
      ),
    ),
  );

  afterLoggedIntoTerminal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthenticationActions.loggedIntoTerminal),
        switchMap(() => {
          this.router.navigate(['menus']);
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  registration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.register),
      exhaustMap(({ payload: data }) => {
        this.store.dispatch(
          AuthenticationActions.setRegisterButtonState({
            register_spinner: true,
          }),
        );
        return this.authServiceBuilder
          .getService()
          .register(data)
          .pipe(
            mergeMap((successData: LoginSuccess) => {
              this.authServiceBuilder.getService().saveToken(successData);
              return [
                AuthenticationActions.setLogin({ isLogged: true }),
                AuthenticationActions.setRegisterButtonState({
                  register_spinner: false,
                }),
                getUserAndRedirect({ route: ['menus'] }),
              ];
            }),
            catchError((err: unknown) => {
              if (err instanceof HttpErrorResponse && err?.error) {
                const errors: FormErrors = extractFormErrors(err.error);
                return [
                  AuthenticationActions.setRegisterButtonState({
                    register_spinner: false,
                  }),
                  setFormErrors({ payload: errors }),
                ];
              }
              return EMPTY;
            }),
          );
      }),
    ),
  );

  checkInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.checkInvitation),
      switchMap(({ key }) =>
        this.authServiceBuilder
          .getService()
          .checkInvitation(key)
          .pipe(
            mergeMap((result) => [
              AuthenticationActions.setInvitationResult({ payload: result }),
            ]),
            catchError((error: unknown) => [
              AuthenticationActions.setInvitationError({
                message: (error as HttpErrorResponse)?.error?.key?.[0],
              }),
            ]),
          ),
      ),
    ),
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.resetPassword),
      tap(() => {
        this.store.dispatch(
          AuthenticationActions.setResetButtonState({ reset_spinner: true }),
        );
      }),
      switchMap(({ email, formId }) => {
        return this.authServiceBuilder
          .getService()
          .resetPassword({ email })
          .pipe(
            mergeMap(() => [
              showSnackbarMessage({
                message: this.transloco.translate(this.RESET_PASSWORD_SUCCESS),
                snackClass: 'success-snack',
              }),
              AuthenticationActions.setResetButtonState({
                reset_spinner: false,
              }),
              clearFormErrors({ payload: undefined }),
            ]),
            catchError((error: unknown) => [
              AuthenticationActions.setResetButtonState({
                reset_spinner: false,
              }),
              handleHttpError({ error, formId }),
            ]),
          );
      }),
    ),
  );

  confirmEmailLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.confirmEmail),
      switchMap(() => [
        AuthenticationActions.setConfirmEmailLoading({ payload: true }),
      ]),
    ),
  );

  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.confirmEmail),
      withLatestFrom(this.store.select(authenticationFeature.selectIsLogged)),
      switchMap(([{ key }, isLoggedIn]) =>
        this.authServiceBuilder
          .getService()
          .confirmEmail(key)
          .pipe(
            mergeMap((response) => {
              const unauthenticated = response.access && response.refresh;
              if (unauthenticated && response.access) {
                this.authServiceBuilder.getService().saveToken(response);
              }
              return [
                ...(unauthenticated
                  ? [AuthenticationActions.setLogin({ isLogged: true })]
                  : []),
                clearFormErrors({ payload: undefined }),
                AuthenticationActions.setConfirmEmailLoading({
                  payload: false,
                }),
                AuthenticationActions.setConfirmEmailSuccessOrFail({
                  payload: true,
                }),
                getUserAndRedirect({ route: ['menus'], delay: 4000 }),
              ];
            }),
            catchError((error: unknown) => [
              handleHttpError({ error }),
              AuthenticationActions.setConfirmEmailSuccessOrFail({
                payload: false,
              }),
              AuthenticationActions.setConfirmEmailLoading({ payload: false }),
              ...((error as HttpErrorResponse).status === 403 ||
              (isLoggedIn && (error as HttpErrorResponse).status === 409)
                ? [
                    getUserAndRedirect({
                      route: ['menus'],
                      delay:
                        (error as HttpErrorResponse).status === 409 ? 3000 : 0,
                    }),
                  ]
                : []),
              ...((error as HttpErrorResponse).status === 409 && !isLoggedIn
                ? [AuthenticationActions.redirectAfterLoggingOut()]
                : []),
            ]),
          ),
      ),
    ),
  );

  confirmPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.confirmPasswordReset),
      withLatestFrom(this.store.pipe(select(selectRouteParams))),
      switchMap(
        ([
          {
            payload: { new_password1, new_password2 },
            formId,
          },
          { token, uid },
        ]) => {
          this.store.dispatch(
            AuthenticationActions.setResetButtonState({ reset_spinner: true }),
          );
          return this.authServiceBuilder
            .getService()
            .confirmPasswordReset({
              new_password1,
              new_password2,
              uid: uid as string,
              token: token as string,
            })
            .pipe(
              mergeMap((successData: ResetSuccess) => {
                if (successData.access)
                  this.authServiceBuilder.getService().saveToken(successData);
                return [
                  clearFormErrors({ payload: undefined }),
                  showSnackbarMessage({ message: successData.detail }),
                  AuthenticationActions.setLogin({ isLogged: true }),
                  AuthenticationActions.setResetButtonState({
                    reset_spinner: false,
                  }),
                  getUserAndRedirect({ route: ['menus'] }),
                ];
              }),
              catchError((error: unknown) => [
                AuthenticationActions.setResetButtonState({
                  reset_spinner: false,
                }),
                AuthenticationActions.handleConfirmPasswordResetError({
                  error,
                  formId,
                }),
              ]),
            );
        },
      ),
    ),
  );

  handleConfirmPasswordResetError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.handleConfirmPasswordResetError),
      switchMap(({ error, formId }) => {
        if (
          error instanceof HttpErrorResponse &&
          (error.error.uid || error.error.token)
        ) {
          error = new HttpErrorResponse({
            ...error,
            error: { [NON_FIELD_ERRORS]: [this.invalidPasswordResetLink] },
          });
        }
        return [handleHttpError({ error, formId })];
      }),
    ),
  );

  resendEmailConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.resendEmailConfirmation),
      switchMap(() =>
        this.authServiceBuilder
          .getService()
          .resendEmailConfirmation()
          .pipe(
            mergeMap(() => []),
            catchError((error: unknown) => [
              handleHttpError({ error, forceSnackbar: true }),
            ]),
          ),
      ),
    ),
  );

  cleanStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.cleanStates),
      switchMap(() => [resetMenusReducer(), clearOrdersPage()]),
    ),
  );

  showDialogToVerifyPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthenticationActions.showDialogToVerifyPassword),
        switchMap(() => {
          this.dialog.open(VerifyPasswordDialog, {
            data: {
              verifyCallback: (data: PasswordVerificationForm) =>
                this.store.dispatch(
                  AuthenticationActions.verifyPasswordAndRedirectToSettings(
                    data,
                  ),
                ),
            },
            disableClose: true,
          });
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  verifyPasswordAndRedirectToSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.verifyPasswordAndRedirectToSettings),
      withLatestFrom(this.store.pipe(select(usersFeature.selectCurrentUser))),
      switchMap(([{ password, formId }, user]) =>
        this.authServiceBuilder
          .getService()
          .verifyPassword(user?.url, password)
          .pipe(
            mergeMap(() => {
              this.dialog.closeAll();
              this.router.navigate(['/settings'], {
                state: { forceToSettings: true },
              });
              return EMPTY;
            }),
            catchError((error: unknown) => [
              handleHttpError({ error, formId }),
            ]),
          ),
      ),
    ),
  );

  verifyPasswordAndLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.verifyPasswordAndLogout),
      withLatestFrom(this.store.pipe(select(usersFeature.selectCurrentUser))),
      switchMap(([{ password, formId }, user]) =>
        this.authServiceBuilder
          .getService()
          .verifyPassword(user?.url, password)
          .pipe(
            mergeMap(() => {
              this.dialog.closeAll();
              return [AuthenticationActions.logoutCompletely()];
            }),
            catchError((error: unknown) => [
              handleHttpError({ error, formId }),
            ]),
          ),
      ),
    ),
  );

  private readonly destroyed$ = new Subject<void>();
  private invalidPasswordResetLink: string;
  private readonly HERE = 'shared.here';
  private readonly RESET_LINK = 'password-reset.invalid-link';
  private readonly RESET_PASSWORD_SUCCESS = 'password-reset.success-message';
  private messageNotCheckedIn: string;
  private readonly messageNotCheckedInKey = 'app.offline.errors.not-checked-in';
  private messageNotEnabled: string;
  private readonly messageNotEnabledKey = 'app.offline.errors.not-enabled';

  constructor() {
    this.getUnauthInvalidUrlMessage();
    this.getOfflineErrorMessages();
    this.transloco.langChanges$.subscribe(() =>
      this.getUnauthInvalidUrlMessage(),
    );
  }

  getUnauthInvalidUrlMessage(): void {
    this.transloco
      .selectTranslate(this.HERE)
      .pipe(
        switchMap((here) =>
          this.transloco.selectTranslate(this.RESET_LINK, {
            link: `<a href="reset-password">${here}</a>`,
          }),
        ),
      )
      .subscribe((msg: string) => (this.invalidPasswordResetLink = msg));
  }

  getOfflineErrorMessages(): void {
    this.transloco
      .selectTranslate(this.messageNotCheckedInKey)
      .subscribe((msg: string) => (this.messageNotCheckedIn = msg));
    this.transloco
      .selectTranslate(this.messageNotEnabledKey)
      .subscribe((msg: string) => (this.messageNotEnabled = msg));
  }
}
