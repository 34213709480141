@if (data.title) {
  <h2 mat-dialog-title class="dialog-title">
    {{ data.title | transloco }}
  </h2>
}
<mat-dialog-content>
  <p class="content">
    @if (data.text) {
      <span [innerHTML]="data.text | transloco"></span>
    }
    @if (data.link) {
      <a [href]="data.link">{{ data.linkText | transloco }}</a>
    }
  </p>
</mat-dialog-content>
<mat-dialog-actions class="actions" align="end">
  @if (data.cancelable === true) {
    <button
      data-cy="simple-dialog-cancel"
      mat-flat-button
      [mat-dialog-close]="false"
      class="action-button cancel-button"
    >
      {{ data.cancelText ?? 'shared.cancel' | transloco }}
    </button>
  }
  <button
    data-cy="simple-dialog-confirm"
    mat-flat-button
    class="action-button"
    cdkFocusInitial
    [mat-dialog-close]="true"
    [color]="data.warn ? 'warn' : 'primary'"
  >
    @if (data.confirmIcon) {
      <mat-icon>{{ data.confirmIcon }}</mat-icon>
    }
    {{ data.confirmText ?? 'shared.confirm' | transloco }}
  </button>
</mat-dialog-actions>
