import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { Course } from '../../shared/models/menus';
import {
  addCreatedCourse,
  removeDeletedCourse,
  setCourses,
  setCoursesDataLoading,
  setSelectedCourse,
  setUpdatedCourse,
} from './courses.actions';

interface CoursesState {
  items: EntityState<Course>;
  loading: boolean;
  selectedCourse: Course;
}

const adapter: EntityAdapter<Course> = createEntityAdapter<Course>();

const initialState: CoursesState = {
  items: adapter.getInitialState({}),
  loading: false,
  selectedCourse: null,
};

const reducer = createReducer<CoursesState>(
  initialState,
  on(addCreatedCourse, (state, { payload }) => {
    return {
      ...state,
      items: adapter.setAll(
        [payload, ...adapter.getSelectors().selectAll(state.items)],
        state?.items,
      ),
    };
  }),
  on(setCourses, (state, { items }) => {
    return {
      ...state,
      items: adapter.setAll(items.results, state.items),
    };
  }),
  on(setCoursesDataLoading, (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  }),
  on(removeDeletedCourse, (state, { courseId }) => {
    return {
      ...state,
      items: adapter.removeOne(courseId, state.items),
    };
  }),
  on(setSelectedCourse, (state, { course }) => {
    return {
      ...state,
      selectedCourse: course,
    };
  }),

  on(setUpdatedCourse, (state, { course, courseId }) => {
    return {
      ...state,
      items: adapter.updateOne({ id: courseId, changes: course }, state?.items),
    };
  }),
);

export const coursesFeature = createFeature({
  name: 'courses',
  reducer,
  extraSelectors: ({ selectItems }) => ({
    selectCoursesEntities: createSelector(
      selectItems,
      adapter.getSelectors().selectAll,
    ),
  }),
});
