import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'win-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, MatIconModule, NgOptimizedImage],
})
export class MenuCardComponent {
  readonly description = input.required<string>();
  readonly img = input.required<string>();
  readonly external = input(false);
  readonly link = input<string>();
  readonly title = input.required<string>();
}
