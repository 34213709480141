import { Component, input, output } from '@angular/core';
import {
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoPipe } from '@jsverse/transloco';
import { LoginForm } from 'src/app/shared/models/authentication';
import { FieldWithErrorsComponent } from '../../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../../shared/components/form-with-errors/form-with-errors.component';

@Component({
  selector: 'win-simple-login',
  templateUrl: './simple-login.component.html',
  styleUrls: [
    '../../../shared/styles/authentication.scss',
    './simple-login.component.scss',
  ],
  imports: [
    MatCardModule,
    FormWithErrorsComponent,
    ReactiveFormsModule,
    FieldWithErrorsComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterLink,
    TranslocoPipe,
  ],
})
export class SimpleLoginComponent {
  readonly spinnerState = input(false);
  readonly login = output<LoginForm>();

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32),
    ]),
  });

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.login.emit({
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
      });
    }
  }
}
