import { createFeature, createReducer, on } from '@ngrx/store';
import {
  InterfaceLanguage,
  LANGUAGES,
} from 'src/app/shared/constants/languages';
import { FormErrors } from 'src/app/shared/models/globals';
import * as GlobalActions from './global.actions';

interface GlobalState {
  language: InterfaceLanguage;
  snackbarLastError: string;
  formErrors: FormErrors;
  globalError: string;
  autocompleteSearch: string[];
}

const initialState: GlobalState = {
  language: LANGUAGES.en,
  snackbarLastError: null,
  formErrors: null,
  globalError: null,
  autocompleteSearch: null,
};

const reducer = createReducer<GlobalState>(
  initialState,
  on(GlobalActions.setGlobalLanguage, (state, { payload: language }) => ({
    ...state,
    language,
  })),
  on(GlobalActions.addFormError, (state, { error }) => {
    const errors = state?.formErrors;
    const nonFieldErrors = errors?.non_field_errors;
    let newErrors: FormErrors;

    if (!errors) newErrors = {};
    else newErrors = { ...errors };

    if (!nonFieldErrors) newErrors.non_field_errors = [error];
    else newErrors.non_field_errors = [...nonFieldErrors, error];

    return {
      ...state,
      formErrors: newErrors,
    };
  }),
  on(GlobalActions.setFormErrors, (state, { payload }) => ({
    ...state,
    formErrors: { ...state.formErrors, ...payload },
  })),
  on(GlobalActions.clearFormErrors, (state, { payload: errorName }) => ({
    ...state,
    formErrors: {
      ...state?.formErrors,
      [errorName]: undefined,
    },
  })),
  on(GlobalActions.setAutocompleteSearch, (state, { results }) => ({
    ...state,
    autocompleteSearch: results,
  })),
  on(GlobalActions.setGlobalError, (state, { error }) => ({
    ...state,
    globalError: error,
  })),
);

export const globalFeature = createFeature({
  name: 'global',
  reducer,
});
