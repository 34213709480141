import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  OnInit,
  inject,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  consumerNameTerminalLogin,
  consumerTerminalLogin,
  login,
  roomLogin,
  validateRoom,
} from 'src/app/redux/authentication/authentication.actions';

import { setOfflineConsumer } from '../../redux/offline-mode/offline-mode.actions';
import { fetchOrdersOfConsumer } from '../../redux/orders/orders.actions';
import { OrganisationFeatures } from '../../shared/models/user';
import { fetchOrdersCount } from './../../redux/orders/orders.actions';
import { LoginForm } from './../../shared/models/authentication';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoPipe } from '@jsverse/transloco';
import { TerminalLoginComponent } from './terminal-login/terminal-login.component';
import { SimpleLoginComponent } from './simple-login/simple-login.component';
import { UnauthenticatedLoginComponent } from './unauthenticated-login/unauthenticated-login.component';
import { LiveOrderViewComponent } from './live-order-view/live-order-view.component';
import { AsyncPipe } from '@angular/common';
import { globalFeature } from 'src/app/redux/global/global.state';
import { ordersFeature } from 'src/app/redux/orders/orders.state';
import { usersFeature } from 'src/app/redux/user/user.state';
import { authenticationFeature } from 'src/app/redux/authentication/authentication.state';

@Component({
  selector: 'win-login',
  templateUrl: './login.container.html',
  styleUrls: ['./login.container.scss'],
  imports: [
    LiveOrderViewComponent,
    UnauthenticatedLoginComponent,
    SimpleLoginComponent,
    TerminalLoginComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class LoginContainer implements OnInit {
  private readonly store = inject(Store);
  private readonly activatedRouter = inject(ActivatedRoute);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private readonly destroyRef = inject(DestroyRef);

  gloablLanguage$ = this.store.pipe(select(globalFeature.selectLanguage));
  showSimpleLoginForm$ = this.store.pipe(select(usersFeature.selectIsUser));
  terminalType$ = this.store.pipe(select(usersFeature.selectTerminalType));
  terminalPrivacy$ = this.store.pipe(
    select(usersFeature.selectTerminalPrivacy),
  );
  roomDetails$ = this.store.pipe(select(usersFeature.selectRoomDetail));
  isManager$ = this.store.pipe(select(usersFeature.selectIsManager));
  ordersRemainingCount$ = this.store.pipe(
    select(ordersFeature.selectOrdersRemainingCount),
  );
  ordersDoneCount$ = this.store.pipe(
    select(ordersFeature.selectOrdersDoneCount),
  );
  roomLoginBar$ = this.store.pipe(
    select(authenticationFeature.selectRoomLoginBar),
  );
  roomDetailsLoadingBar$ = this.store.pipe(
    select(authenticationFeature.selectRoomDetailsLoadingBar),
  );
  showLiveOrder$ = this.store.pipe(
    select(usersFeature.selectTerminalTypeCanteen),
  );
  rfidEnabled$ = this.store.pipe(
    select(usersFeature.selectFeature(OrganisationFeatures.rfid)),
  );
  aggregatedOrderTakingEnabled$ = this.store.pipe(
    select(
      usersFeature.selectFeature(OrganisationFeatures.aggregated_ordertaking),
    ),
  );
  spinnerState$ = this.store.pipe(
    select(authenticationFeature.selectLoginSpinner),
  );

  orders$ = this.store.pipe(select(ordersFeature.selectOrderEntities));
  ordersFetched$ = this.store.pipe(select(ordersFeature.selectOrdersFetched));

  unauthAggregatedOrderTakingEnabled$ = this.store.pipe(
    select(authenticationFeature.selectUnauthAggregatedOrderFeature),
  );
  unauthInvalidUrlMessage$ = this.store.pipe(
    select(authenticationFeature.selectUnauthInvalidUrlMessage),
  );
  unauthSpinnerState$ = this.store.pipe(
    select(authenticationFeature.selectUnauthSpinner),
  );

  unauthenticatedOrder = false;

  @HostBinding('class') get HeadingClass() {
    let showSimpleLoginForm = false;
    this.store
      .pipe(
        select(usersFeature.selectIsUser),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((ShowSimpleLoginForm) => {
        if (ShowSimpleLoginForm) {
          showSimpleLoginForm = true;
        }
      });
    if (!showSimpleLoginForm || this.unauthenticatedOrder) {
      return 'background';
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    if (
      this.activatedRouter.snapshot.queryParams.key &&
      this.activatedRouter.snapshot.queryParams.auth_room
    ) {
      this.unauthenticatedOrder = true;
      this.cdr.detectChanges();
    }
  }

  fetchOrdersCount = () => {
    this.store.dispatch(
      fetchOrdersCount({
        date: this.dateAdapter.format(new Date(), 'yyyy-MM-dd'),
      }),
    );
  };

  onLogin(data: LoginForm): void {
    this.store.dispatch(login({ ...data, formId: 'simple-login' }));
  }

  onTerminalLogin({
    rfid,
    room,
    formId,
  }: {
    rfid?: string;
    room?: string;
    formId: string;
  }): void {
    this.store.dispatch(
      setOfflineConsumer({
        payload: rfid,
      }),
    );
    this.store.dispatch(roomLogin({ room, rfid, formId }));
  }

  onConsumerTerminalLogin({ uuid }: { uuid: string }): void {
    this.store.dispatch(consumerTerminalLogin({ uuid }));
  }

  onConsumerNameTerminalLogin(data) {
    this.store.dispatch(consumerNameTerminalLogin(data));
  }

  validateRoom({ room, formId }: { room: string; formId: string }): void {
    this.store.dispatch(validateRoom({ room, formId }));
  }

  rfidChanged(rfid: string): void {
    this.store.dispatch(fetchOrdersOfConsumer({ rfid }));
  }
}
