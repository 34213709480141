import { createAction, props } from '@ngrx/store';
import { Consumer } from 'src/app/shared/models/consumers';
import {
  RoomConsumer,
  UnauthConsumer,
  User,
  UserChangedData,
  UserPasswordChangedData,
} from 'src/app/shared/models/user';

export const setUser = createAction(
  `[Login Container] Set user after Login`,
  props<{ payload: User | undefined }>(),
);

export const setUnauthConsumer = createAction(
  `[Unauth API] Set simple consumer object`,
  props<{ simpleConsumer: UnauthConsumer }>(),
);

export const fetchConsumerOrganisation = createAction(
  `[Unauth API] Fetch organisation`,
);

export const setConsumer = createAction(
  `[Room login] Set consumer after logging in with RFID`,
  props<{ payload: Consumer }>(),
);

export const setRoomDetail = createAction(
  `[Room detail] Set room details after validating room`,
  props<{ payload: RoomConsumer[] | { message: string } }>(),
);

export const getUserAndRedirect = createAction(
  `[Login Container] After logging in fetch User object and redirect to a route`,
  props<{ route: string[]; delay?: number }>(),
);

export const getUser = createAction(`[User API] Fetch user from the backend`);

export const saveUserLocally = createAction(
  `[User API] Save user locally after successful fetch`,
  props<{ user: User | undefined }>(),
);

export const restoreUserLocally = createAction(
  `[User FE action] Restore User from local storage`,
);

export const removeUserLocally = createAction(
  `[User API] Remove User locally after 401 error or logging out`,
);

export const patchUser = createAction(
  `[Settings Container] Patch user`,
  props<{
    payload: {
      data: Partial<UserChangedData>;
      successMessage?: string;
      snackClass?: string;
      formId?: string;
    };
  }>(),
);

export const changePassword = createAction(
  `[Settings Container] Change user password`,
  props<{ payload: { data: UserPasswordChangedData; formId: string } }>(),
);

export const deleteAccount = createAction(
  `[Settings Container] Delete account`,
);

export const connectWebSocket = createAction(
  `[Websocket] Connect to websocket`,
);

export const disconnectWebSocket = createAction(
  `[Websocket] Disconnect from websocket`,
);

export const reportWebSocketError = createAction(
  `[Websocket] Connect to websocket error reporting`,
);
