import { DietDetailShort } from 'src/app/shared/models/diets';

import { InterfaceLanguage } from './../constants/languages';
import { Consistency, REPEAT_OPTIONS, Texture } from './orders';
import {
  ACCESSES_ORDERING,
  AccessStatusFilter,
  ConsumerStatusFilter,
} from 'src/app/managing/models/managable-accesses';
import { ORDERS_ORDERING } from 'src/app/managing/models/managable-orders';

export enum RoleChoice {
  CONSUMER = 1,
  ADMIN = 2,
  EDITOR = 3,
  READONLY = 4,
  READONLY_EDITOR = 5,
  AGENT = 6,
}

export enum TerminalType {
  RFID = 1,
  ROOM = 2,
  CANTEEN = 3,
}

export enum PrivacyLevel {
  ANONYMOUS = 1,
  NAME_ONLY = 2,
  SHOW_ALL = 3,
}

export interface SimpleConsumer {
  id: number;
  location: number;
  location_detail: OrganisationLocation;
  organisation: Organisation;
  url: string;
}

export interface ConsumerCondensed {
  id: number;
  url: string;
  first_name: string;
  last_name: string;
  name: string;
  diets: string[];
  diets_detail: DietDetailShort[];
  consistency?: Consistency;
  texture?: Texture;
  portion_size?: number;
  allergies?: string[];
  intolerances?: string[];
  checkin: string;
  checkout: string;
  checkin_time: string;
  checkout_time: string;
  room: string;
  room_floor: string;
  custom_data: CustomData;
  table_number: string;
  vip: boolean;
  has_birthday?: boolean;
  type: number;
  type_name: string;
  department: string;
}

export interface RoomConsumer {
  id: number;
  url: string;
  uuid: string;
  first_name: string;
  last_name: string;
  name: string;
  location: number;
  location_detail: OrganisationLocation;
  type: string;
  type_name: string;
  vip: boolean;
  room: string;
  room_floor: string;
  rfid: string;
}

export interface UserCondensed {
  id: number;
  url: string;
  first_name: string;
  last_name: string;
  name: string;
  location: number;
  diets: string[];
  diets_detail: DietDetailShort[];
  checkin: string;
  checkout: string;
  custom_data: CustomData;
  vip: boolean;
  type: number;
  type_name: string;
  department: string;
}

export interface User {
  id: number;
  url: string;
  organisation: Organisation;
  location: number;
  location_detail: OrganisationLocation;
  terminal: Terminal | null;
  status: UserStatus;
  settings: UserSettings;
  username: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  is_kds: boolean;
  is_service: boolean;
  email: string;
  role: RoleChoice;
  type_name?: string;
  custom_data?: CustomData;
  diets_detail?: DietDetailShort[];
  name: string;
  room?: string;
  diets?: string[];
  type?: number;
  portion_size?: number;
  checkin?: string;
  checkout?: string;
  vip: boolean;
  department: string;
}

export type SimpleUser = Pick<User, 'email' | 'first_name' | 'last_name'>;

export interface InvitationResult {
  email: string;
  first_name: string;
  last_name: string;
  role: RoleChoice;
  invitation: Invitation;
}

export interface Invitation {
  invited_at: string;
  location: number;
  location_detail: OrganisationLocation;
  organisation: Organisation;
  inviter: SimpleUser;
}

export type CustomDataToFormGroupTypes =
  | 'text'
  | 'number'
  | 'checkbox'
  | 'textarea';

export interface CustomDataToFormGroupValue {
  formControlName: string;
  order: number;
  type: CustomDataToFormGroupTypes;
  value: string | number | boolean;
  placeholder: string;
  required: boolean;
}

export type CustomDataToFormGroup = Record<string, CustomDataToFormGroupValue>;

export enum OrganisationFeatures {
  birthdays = 'birthdays',
  checkin = 'checkin',
  checkin_time = 'checkin_time',
  checkout = 'checkout',
  checkout_time = 'checkout_time',
  allergies = 'allergies',
  intolerances = 'intolerances',
  consistency = 'consistency',
  texture = 'texture',
  aggregated_ordertaking = 'aggregated_ordertaking',
  external_id = 'external_id',
  rfid = 'rfid',
  room = 'room',
  room_floor = 'room_floor',
  staff_ordertaking = 'staff_ordertaking',
  table_number = 'table_number',
  qr_code = 'qr_code',
  public_link = 'public_link',
  automated_order_consumers = 'automated_order_consumers',
  automated_order_users = 'automated_order_users',
  portion_size = 'portion_size',
  department = 'department',
}

export type StandardPortionSize = 0.5 | 1 | 1.5 | 2;

export interface OrganisationUnauth {
  id: number;
  name: string;
  identifier: string;
  logo: string;
  acronym: string;
  enabled_features: OrganisationFeatures[];
  default_language: InterfaceLanguage;
  default_timezone: string;
  custom_translations_en: string;
  custom_translations_fr: string;
  custom_translations_es: string;
  custom_translations_de: string;
  custom_translations_it: string;
  order_by_time: string;
  order_by_days_before: number;
  order_days_until: number;
  allow_order_allergies: boolean;
  allow_order_intolerances: boolean;
  maximum_components_order: number;
  quantity_input_field: boolean;
  quantity_input_field_increment: number;
  unauth_consumers_quantity_selection: boolean;
  unauth_consumers_portion_size_selection: boolean;
  unauth_privacy: PrivacyLevel;
  disabled_portion_sizes: StandardPortionSize[];
  allow_order_past: boolean;
}

export interface Organisation {
  id: number;
  identifier: string;
  logo: string;
  name: string;
  acronym: string;
  order_by_time: string;
  order_by_days_before: number;
  order_days_until: number;
  supported_custom_data: SupportedCustomData[];
  supported_custom_data_consumers: SupportedCustomData[];
  enabled_features: OrganisationFeatures[];
  enabled_reports: string[];
  date_joined: string;
  allow_order_allergies?: boolean;
  allow_order_intolerances?: boolean;
  allow_order_past?: boolean;
  unauth_consumers_quantity_selection: boolean;
  unauth_consumers_portion_size_selection: boolean;
  unauth_privacy: PrivacyLevel;
  agent_show_admin_items: boolean;
  agent_manage_repeat_orders: boolean;
  quantity_input_field: boolean;
  quantity_input_field_increment: number;
  disabled_repetition_modes: REPEAT_OPTIONS[];
  disabled_portion_sizes: StandardPortionSize[];
  manage_orders_default_days: number;
  manage_consumers_default_filter: ConsumerStatusFilter;
  manage_users_default_filter: AccessStatusFilter;
  real_time_orders: boolean;
  allergens_extended: boolean;
  allergens_lac_extended: boolean;
}

export type CustomData = Record<string, string | number | boolean>;

export type SupportedCustomDataTypes = 'string' | 'number' | 'boolean' | 'text';

export interface SupportedCustomData {
  key: string;
  type: SupportedCustomDataTypes;
  order: number;
  locations?: number[];
  required: boolean;
}

export type ManageColumnsKeys =
  | 'columns_consumers'
  | 'columns_users'
  | 'columns_orders';

export type ManageSortingKeys =
  | 'sorting_consumers'
  | 'sorting_orders'
  | 'sorting_users';

export interface UserSettings {
  language: InterfaceLanguage;
  timezone: string;
  orderby_time: string;
  orderby_days_offset: number;
  columns_consumers: string[];
  columns_users: string[];
  columns_orders: string[];
  sorting_consumers: ACCESSES_ORDERING;
  sorting_orders: ORDERS_ORDERING;
  sorting_users: ACCESSES_ORDERING;
}

export interface UserStatus {
  consumer: string;
  orderby_time: string;
  orderby_days_offset: number;
  orderby_until: number;
  email_verified: boolean;
  date_joined: string;
  user_identifier: number;
}

export interface OrganisationLocation {
  id: number;
  logo: string;
  name: string;
  acronym: string;
  order_by_time: string;
  order_by_days_before: number;
  allow_order_allergies?: boolean;
  allow_order_intolerances?: boolean;
  allow_order_past?: boolean;
  unauth_consumers_quantity_selection: boolean;
  unauth_consumers_portion_size_selection: boolean;
  unauth_privacy: PrivacyLevel;
  agent_show_admin_items: boolean;
  agent_manage_repeat_orders: boolean;
  quantity_input_field: boolean;
  quantity_input_field_increment: number;
  disabled_repetition_modes: REPEAT_OPTIONS[];
  disabled_portion_sizes: (0.5 | 1 | 1.5 | 2)[];
  order_days_until: number;
  manage_orders_default_days: number;
  manage_consumers_default_filter: ConsumerStatusFilter;
  manage_users_default_filter: AccessStatusFilter;
  real_time_orders: boolean;
}

export interface Terminal {
  protected: boolean;
  id: number;
  name: string;
  identifier: string;
  order_by_time: string;
  order_by_days_before: number;
  type: TerminalType;
  privacy: PrivacyLevel;
}

export const terminalModeSelectOptions = [
  {
    id: 1,
    name: 'anonymous',
  },
  {
    id: 2,
    name: 'name-only',
  },
  {
    id: 3,
    name: 'all',
  },
];

export interface UserNameChangedData {
  first_name: string;
  last_name: string;
}

export interface UserEmailChangedData {
  email: string;
}

export interface UserLanguageChangedData {
  settings: {
    language: InterfaceLanguage;
  };
}

export interface UserColumnsChangedData {
  settings: {
    columns_consumers?: string[];
    columns_users?: string[];
    columns_orders?: string[];
    sorting_consumers?: string;
    sorting_orders?: string;
    sorting_users?: string;
  };
}

export type UserChangedData =
  | UserNameChangedData
  | UserEmailChangedData
  | UserLanguageChangedData;

export interface UserPasswordChangedData {
  new_password1: string;
  new_password2: string;
}

export interface UnauthConsumer {
  id: number;
  url: string;
  first_name: string;
  last_name: string;
  name: string;
  organisation: OrganisationUnauth;
  location: number;
  location_detail: OrganisationLocation;
  diets: string[];
  diets_detail: DietDetailShort[];
  portion_size?: number;
  allergies?: string[];
  intolerances?: string[];
  consistency?: number;
  texture?: number;
  checkin: string;
  checkout: string;
  checkin_time: string;
  checkout_time: string;
  room: string;
  room_floor: string;
  department: string;
  custom_data: CustomData;
  table_number: string;
  vip: boolean;
  has_birthday: boolean;
  type: number;
  type_name: string;
}

export interface Report {
  id: number;
  name: string;
  format: 'html/pdf' | 'excel';
  type: ReportType;

  downloading?: boolean;
}

export enum ReportType {
  CONSUMERS = 'consumers',
  ORDERS = 'orders',
  PERSONAL = 'personal',
}

export type SupportedReportFormats = 'html/pdf' | 'excel';

export type ReportFormat = 'csv' | 'excel' | 'pdf';
