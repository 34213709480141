import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'win-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTooltipModule, MatButtonModule, MatIconModule],
})
export class SubNavigationComponent {
  readonly disabled = input(false);
  readonly label = input.required<string>();
  readonly tooltip = input<string>();
  readonly navigate = output<void>();
}
