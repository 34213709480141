<div class="wrapper">
  <h1 class="mat-h1">
    {{ (invitation ? "registration.invitation.title" : "registration.title") |
    transloco }}
  </h1>
  <p class="mat-body subtitle">{{ "registration.subtitle" | transloco }}</p>
  <mat-card
    appearance="outlined"
    class="card mat-elevation-z2"
    data-cy="registration-card"
    [class.invitation-card]="invitation"
    [class.invitation-error]="(invitationErrorMsg$ | async) !== null"
  >
    @if (invitationResult$ | async) {
    <win-inviter-info
      class="inviter-info"
      data-cy="inviter-info"
      [data]="invitationResult$ | async"
    ></win-inviter-info>
    }
    <mat-card-content>
      @if ((invitationResult$ | async) && ((invitationResult$ |
      async)?.invitation?.location_detail?.logo || (invitationResult$ |
      async)?.invitation?.organisation.logo)) {
      <img
        class="org-logo"
        data-cy="org-logo"
        [src]="(invitationResult$ | async)?.invitation?.location_detail?.logo || (invitationResult$ | async)?.invitation?.organisation.logo"
        alt="Organisation logo"
      />
      } @if ((invitationErrorMsg$ | async) === null) {
      <win-form-with-errors
        class="form"
        [formGroup]="registrationForm"
        formId="registration"
      >
        <div class="fields">
          <win-field-with-errors
            icon="email"
            type="email"
            class="field"
            [label]="'registration.email.placeholder' | transloco"
            translationKey="registration.email.errors"
            formControlName="email"
            [showProgressBar]="checkingEmail$ | async"
            [required]="true"
          ></win-field-with-errors>
          <win-field-with-errors
            icon="person"
            type="text"
            class="field"
            [autocomplete]="'given-name'"
            [label]="'registration.firstName.placeholder' | transloco"
            formControlName="first_name"
            translationKey="registration.firstName.errors"
            [required]="true"
          ></win-field-with-errors>
          <win-field-with-errors
            icon="person"
            type="text"
            class="field"
            [autocomplete]="'family-name'"
            [label]="'registration.lastName.placeholder' | transloco"
            formControlName="last_name"
            translationKey="registration.lastName.errors"
            [required]="true"
          ></win-field-with-errors>
          <win-field-with-errors
            icon="lock"
            type="password"
            class="field"
            [label]="'registration.password1.placeholder' | transloco"
            formControlName="password1"
            translationKey="registration.password1.errors"
            [required]="true"
          ></win-field-with-errors>
          <win-field-with-errors
            icon="lock"
            type="password"
            class="field"
            [label]="'registration.password2.placeholder' | transloco"
            formControlName="password2"
            translationKey="registration.password2.errors"
            [required]="true"
          ></win-field-with-errors>
          <div class="terms-wrapper">
            <mat-checkbox
              class="accept-terms"
              color="primary"
              formControlName="terms"
              name="terms"
              data-cy="terms"
              required
            >
              <a [href]="termsLinks[globalLanguage$ | async]" target="_blank"
                >{{'registration.terms.placeholder' | transloco}}</a
              >
            </mat-checkbox>
            @if (registrationForm.get('terms')?.invalid &&
            registrationForm.get('terms')?.touched) {
            <mat-error>
              @for (error of registrationForm.get('terms')?.errors | keyvalue;
              track error) {
              <span>
                {{"registration.terms.errors."+error.key | transloco}}
              </span>
              }
            </mat-error>
            }
          </div>
        </div>
        <button
          class="submit-button"
          mat-flat-button
          color="primary"
          data-cy="submit-registration"
          type="submit"
          (click)="onSubmit()"
          [disabled]=" spinnerState$ | async"
        >
          @if ( spinnerState$ | async ) {
          <span>
            <mat-spinner
              class="loading-spinner"
              mode="indeterminate"
              [diameter]="24"
            ></mat-spinner>
          </span>
          } {{'registration.submit-button' | transloco}}
        </button>
      </win-form-with-errors>
      } @if (invitationErrorMsg$ | async) {
      <mat-error> {{invitationErrorMsg$ | async}} </mat-error>
      }
    </mat-card-content>
  </mat-card>
  <div class="links mat-body">
    <a data-cy="login-link" routerLink="/login"
      >{{'app.links.to-login' | transloco}}</a
    >
  </div>
</div>
