import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ChangeDetectorRef,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { KeyValuePipe } from '@angular/common';
import { ServerFormErrorDirective } from '../../directives/server-form-error.directive';

@Component({
  selector: 'win-form-with-errors',
  templateUrl: './form-with-errors.component.html',
  styleUrls: ['./form-with-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    KeyValuePipe,
    MatFormFieldModule,
    ReactiveFormsModule,
    ServerFormErrorDirective,
  ],
})
export class FormWithErrorsComponent implements AfterViewInit {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);

  readonly formGroup = input.required<FormGroup>();
  readonly formId = input<string>();

  ngAfterViewInit(): void {
    this.formGroup()
      .statusChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
  }

  handleLinks(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.tagName === 'A') {
      this.router.navigate([target.getAttribute('href')]);
      event.preventDefault();
    }
  }
}
