import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { authenticationFeature } from 'src/app/redux/authentication/authentication.state';
import { usersFeature } from 'src/app/redux/user/user.state';

export const emailVerificationGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
) => {
  const store = inject(Store);
  const router = inject(Router);

  // Check if the route requires verified or unverified state
  const requiresVerified = (route.data['requiresVerified'] as boolean) ?? true;

  return store.pipe(
    select(usersFeature.selectEmailVerified),
    withLatestFrom(
      store.pipe(select(authenticationFeature.selectIsSimpleAuth)),
    ),
    take(1),
    map(([isVerified, isSimpleAuth]) => {
      if (!isSimpleAuth && requiresVerified && !isVerified) {
        router.navigate(['/verify-email']);
        return false;
      }
      if (!requiresVerified && (isVerified || isSimpleAuth)) {
        router.navigate(['/menus'], {
          queryParams: route.queryParams,
        });
        return false;
      }
      return true;
    }),
  );
};
