import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { Course } from 'src/app/shared/models/menus';
import { CoursesSliderComponent } from './courses-slider/courses-slider.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'win-build-new-skills',
  templateUrl: './build-new-skills.component.html',
  styleUrls: ['./build-new-skills.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CoursesSliderComponent, MatButtonModule, RouterLink, TranslocoPipe],
})
export class BuildNewSkillsComponent {
  readonly courses = input.required<Course[]>();
  readonly isManager = input.required<boolean>();
  readonly lang = input.required<string>();
  readonly loading = input.required<boolean>();

  readonly addNewCourse = output<void>();
  readonly deleteCourse = output<number>();
  readonly editCourse = output<Course>();
}
