<div class="wrapper">
  @if (showLabel()) {
    <span class="label">{{ label() }}:</span>
  }
  <span class="value-wrapper">
    <span
      class="value"
      [class.strikethrough]="valueOverwritten()"
      [innerHtml]="
        value() === true || value() === false
          ? value()
            ? ('shared.boolean.yes' | transloco)
            : ('shared.boolean.no' | transloco)
          : value()
            ? (value() | safe)
            : ''
      "
    ></span>
    @if (valueOverwritten()) {
      <mat-icon class="overwritten">announcement</mat-icon>&nbsp;<span
        class="value overwritten"
        >{{ valueOverwritten() }}</span
      >
    }
  </span>
</div>
