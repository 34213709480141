<ng-container *ngTemplateOutlet="isTerminal() && stripe"></ng-container>
<ng-container *ngTemplateOutlet="item() && backStripe"></ng-container>

@if (showConsumerInfo()) {
  <win-consumer-info
    class="mat-body consumer-info"
    [consumer]="consumer() || item() || unauthConsumer()"
    [isOffline]="isOffline()"
    [lang]="lang()"
    [showDetail]="showConsumerInfoDetail()"
  ></win-consumer-info>
}

<div class="wrapper">
  @if (menus() === null || menus().length) {
    <div class="header">
      @if (!item() && !isTerminal() && fullName()) {
        <h1 class="mat-h1">
          {{ fullName() }}
        </h1>
      }
      @if ((isManager() || isAgent()) && !item() && !isTerminal()) {
        <p class="mat-body instruction">
          {{ 'menus.instruction-admin' | transloco }}
        </p>
        <win-menus-cards
          class="menus-cards"
          [showThirdCard]="menus()?.length && showThirdMenuCard()"
          [isManager]="isManager()"
          [isKdsUser]="isKdsUser()"
          [isServiceUser]="isServiceUser()"
          (scrollToMenus)="scrollToMenus()"
        ></win-menus-cards>
        @if (staffOrdertaking()) {
          <hr />
        }
      }
    </div>

    @if (staffOrdertaking() || isTerminal() || isConsumer() || item()) {
      <div class="menus-wrapper" #menusRef>
        @if (menus()) {
          @if (specialMenus().length) {
            <div class="menus">
              <h2 class="mat-h2">{{ 'menus.special' | transloco }}:</h2>
              @if (isOffline()) {
                <p class="mat-body offline-notice">
                  {{ 'menus.diets-offline-notice' | transloco }}
                </p>
              }
              @for (menu of specialMenus(); track menu) {
                <win-menu
                  class="menu"
                  [lang]="lang()"
                  [menu]="menu"
                  (openMenu)="
                    (menu.has_orders || menu.can_order) &&
                      selectMenuShowDatepicker.emit(menu)
                  "
                ></win-menu>
              }
            </div>
          }
          @if (dailyMenus().length) {
            <div class="menus">
              <h2 class="mat-h2">{{ 'menus.daily' | transloco }}:</h2>
              @for (menu of dailyMenus(); track menu) {
                <win-menu
                  class="menu"
                  [lang]="lang()"
                  [menu]="menu"
                  (openMenu)="
                    (menu.has_orders || menu.can_order) && selectMenu.emit(menu)
                  "
                ></win-menu>
              }
            </div>
          }
        } @else {
          <ngx-skeleton-loader
            class="skeleton-title"
            [theme]="{
              width: '350px',
              height: '30px',
              'max-width': 'calc(100% - 100px)',
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            class="skeleton"
            count="7"
            [theme]="{
              width: small ? '100%' : '310px',
              height: '106px',
              margin: small ? '10px 0' : '10px 0',
            }"
          ></ngx-skeleton-loader>
        }
      </div>
    }
  } @else {
    <section class="no-menus">
      <div class="header">
        @if (!isTerminal() && !item()) {
          <h1 class="mat-h1">
            {{ fullName() }}
          </h1>
        }
        @if ((isManager() || isAgent()) && !item() && !isTerminal()) {
          <p class="mat-body instruction">
            {{ 'menus.instruction-admin' | transloco }}
          </p>
        }
      </div>
      @if ((isManager() || isAgent()) && !item() && !isTerminal()) {
        <win-menus-cards
          class="menus-cards"
          [showThirdCard]="false"
          [isKdsUser]="isKdsUser()"
          [isManager]="isManager()"
          (scrollToMenus)="scrollToMenus()"
        ></win-menus-cards>
      }
      @if (staffOrdertaking() && !isPastCheckout) {
        <p class="mat-body no-menu-available">
          {{ 'menus.no-menus' | transloco }}
        </p>
      }
      @if (isPastCheckout && item() && (isManager() || isAgent())) {
        <div class="past-checkout">
          <div>
            <mat-icon class="mat-icon">feedback</mat-icon>
            <div class="mat-body consumer-checked-out">
              {{ 'menus.consumer-checked-out' | transloco }}
            </div>
          </div>
          <p class="mat-body">{{ 'menus.past-checkout' | transloco }}</p>
          <div class="datepicker">
            <mat-form-field>
              <mat-label>
                {{ 'menus.checkout-date' | transloco }}
              </mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [min]="minDate"
                [required]="true"
                [formControl]="checkoutDate"
                (click)="picker.open()"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                touchUi
                #picker
                [startAt]="initDate"
              ></mat-datepicker>
              <mat-error>{{
                'app.offline.enable.date-required' | transloco
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      }
    </section>
  }
  <ng-template #backStripe>
    @if (goBackPage()) {
      <win-sub-navigation
        [label]="goBackTranslations[goBackPage()] | transloco"
        (navigate)="goBack.emit()"
      ></win-sub-navigation>
    }
  </ng-template>
</div>

<ng-container *ngTemplateOutlet="isTerminal() && stripe"></ng-container>

<ng-template #stripe>
  <win-save-stripe class="save-stripe">
    <button
      mat-flat-button
      color="primary"
      class="fill-parent"
      mat-button
      (click)="logout.emit()"
    >
      {{ 'shared.done' | transloco }}
    </button>
  </win-save-stripe>
</ng-template>
