import { Routes } from '@angular/router';
import { ConfirmEmailContainer } from './containers/confirm-email/confirm-email.container';
import { LoginContainer } from './containers/login/login.container';
import { MenusContainer } from './containers/menus/menus.container';
import { OrdersCompleteContainer } from './containers/orders-complete/orders-complete.container';
import { OrdersContainer } from './containers/orders/orders.container';
import { RedirectContainer } from './containers/redirect/redirect.container';
import { RegistrationContainer } from './containers/registration/registration.container';
import { ResetPasswordContainer } from './containers/reset-password/reset-password.container';
import { clearMenuHtmlGuard } from './shared/guards/clear-menu-html.guard';
import { deactivateOrdersGuard } from './shared/guards/deactivate-orders.guard';
import { loginGuard } from './shared/guards/login.guard';
import { emailVerificationGuard } from './shared/guards/verified.guard';
import { menusResolver } from './shared/resolvers/menus.resolver';
import { ordersCompleteResolver } from './shared/resolvers/orders-complete.resolver';
import { ordersResolver } from './shared/resolvers/orders.resolver';
import { redirectResolver } from './shared/resolvers/redirect.resolver';
import { VerifyEmailComponent } from './containers/verify-email/verify-email.container';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginContainer,
    canActivate: [loginGuard],
  },
  {
    path: 'registration',
    canActivate: [loginGuard],
    component: RegistrationContainer,
  },
  {
    path: 'registration/:key',
    canActivate: [loginGuard],
    component: RegistrationContainer,
    data: { invitation: true },
  },
  {
    path: 'reset-password',
    component: ResetPasswordContainer,
    canActivate: [loginGuard],
  },
  {
    path: 'reset/:uid/:token',
    component: ResetPasswordContainer,
    canActivate: [loginGuard],
    data: { confirm: true },
  },
  {
    path: 'confirm-email/:key',
    component: ConfirmEmailContainer,
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    canActivate: [loginGuard, emailVerificationGuard],
    data: { requiresVerified: false },
  },
  {
    path: 'menus',
    component: MenusContainer,
    canActivate: [loginGuard, emailVerificationGuard],
    resolve: { menus: menusResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'orders/:menuId/:date/complete',
    component: OrdersCompleteContainer,
    canActivate: [loginGuard, emailVerificationGuard],
    canDeactivate: [clearMenuHtmlGuard],
    resolve: { orders: ordersCompleteResolver },
  },
  {
    path: 'orders/:menuId/:date/:consumer',
    component: OrdersContainer,
    canActivate: [loginGuard, emailVerificationGuard],
    resolve: { orders: ordersResolver },
    canDeactivate: [deactivateOrdersGuard],
  },
  {
    path: 'orders/:menuId/:date:/:created_by',
    component: OrdersContainer,
    canActivate: [loginGuard, emailVerificationGuard],
    resolve: { orders: ordersResolver },
    canDeactivate: [deactivateOrdersGuard],
  },
  {
    path: 'orders/:menuId/:date',
    component: OrdersContainer,
    canActivate: [loginGuard, emailVerificationGuard],
    resolve: { orders: ordersResolver },
    canDeactivate: [deactivateOrdersGuard],
  },
  {
    path: 'redirect',
    component: RedirectContainer,
    canActivate: [loginGuard, emailVerificationGuard],
    resolve: { redirect: redirectResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.routes')
        .then((m) => m.routes)
        .catch(() => {
          window.location.reload();
          return [];
        }),
  },
  {
    path: 'manage',
    loadChildren: () =>
      import('./managing/managing.routes')
        .then((m) => m.managingRoutes)
        .catch(() => {
          window.location.reload();
          return [];
        }),
  },
  {
    path: '**',
    redirectTo: 'menus',
  },
];
