import { Component, input, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { toggleOfflineMode } from 'src/app/redux/offline-mode/offline-mode.actions';
import { OfflineModeService } from 'src/app/shared/services/offline-mode/offline-mode.service';

import { OfflineModeConfirmModalComponent } from './offline-mode-confirm-modal/offline-mode-confirm-modal.component';
import { OfflineModeSpinnerComponent } from './offline-mode-spinner/offline-mode-spinner.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'win-offline-mode',
  templateUrl: './offline-mode.component.html',
  styleUrls: ['./offline-mode.component.scss'],
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslocoPipe,
  ],
})
export class OfflineModeComponent {
  private readonly dialog = inject(MatDialog);
  private readonly offlineModeService = inject(OfflineModeService);
  private readonly store = inject(Store);

  orderCount = input.required<number>();

  isOffline = () => this.offlineModeService.isOffline();

  toggleOffline(): void {
    const confirmDialogRef = this.dialog.open(
      OfflineModeConfirmModalComponent,
      { autoFocus: false },
    );
    confirmDialogRef
      .afterClosed()
      .pipe(filter((d) => !!d))
      .subscribe((data: { accepted: true; date: Date }) => {
        const payload = {};
        if (data.date) {
          const offset = data.date.getTimezoneOffset();
          payload['payload'] = new Date(
            data.date.getTime() - offset * 60 * 1000,
          );
        }
        this.store.dispatch(toggleOfflineMode(payload));
        this.dialog.open(OfflineModeSpinnerComponent, { disableClose: true });
      });
  }
}
