import { createAction, props } from '@ngrx/store';
import { LANGUAGES } from './../../shared/constants/languages';
import { FormErrors, NonFieldError } from '../../shared/models/globals';

export const setGlobalLanguage = createAction(
  `[Authentication screen (login, registration, etc)] Set global language`,
  props<{ payload: keyof typeof LANGUAGES }>(),
);

export const showSnackbarMessage = createAction(
  `[API] Show snackbar message, it can be an error or a normal message`,
  props<{
    errorCode?: number;
    message?: string;
    snackClass?: string;
    button?: string;
    duration?: number;
    action?: () => void;
  }>(),
);

export const handleHttpError = createAction(
  `[API] Handle HTTP error returned from the server`,
  props<{
    error: unknown;
    formId?: string;
    forceSnackbar?: boolean;
  }>(),
);

export const addFormError = createAction(
  `[API] Add form error to the list of non_field_errors`,
  props<{ error: NonFieldError }>(),
);

export const setFormErrors = createAction(
  `[Registration Container] Set authentication errors after registration failure`,
  props<{ payload: FormErrors }>(),
);

export const clearFormErrors = createAction(
  `[Registraton Container] Clear authentication server errors`,
  props<{ payload: string }>(),
);

export const setGlobalMetaTags = createAction(
  `[Meta tags] Set global meta tags`,
  props<{ payload: keyof typeof LANGUAGES }>(),
);

export const fetchAutocompleteSearch = createAction(
  `[Search API] Fetch autocomplete search`,
  props<{
    field: string;
    value: string;
    model: 'consumer' | 'user' | 'order' | 'service';
  }>(),
);

export const setAutocompleteSearch = createAction(
  `[Search API] Set autocomplete custom data`,
  props<{ results: string[] }>(),
);

export const setGlobalError = createAction(
  `[API] Set global error locally`,
  props<{ error: string }>(),
);
