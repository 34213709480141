<win-sub-navigation
  [disabled]="!menu() || menu()?.can_order === false"
  [label]="'orders.confirm.back' | transloco"
  [tooltip]="
    !menu()
      ? ('manage-orders.menu-not-found' | transloco)
      : menu()?.can_order === false
        ? ('orders.confirm.blocked' | transloco)
        : ''
  "
  (navigate)="back()"
></win-sub-navigation>

@if (showConsumerInfo()) {
  <win-consumer-info
    [consumer]="consumer() || editedItem()"
    [dietsScheduled]="dietsScheduled"
    [hide]="true"
    [isOffline]="isOffline()"
    [lang]="lang()"
    [orderCompleted]="true"
    [showDetail]="showConsumerInfoDetail()"
    class="mat-body consumer-info"
  ></win-consumer-info>
}
@if (orders()?.length > 0) {
  <div
    class="success-gradient"
    [class.extend-height]="
      ordersFetched() &&
      orders()?.length > 0 &&
      !manageRepeatOrders() &&
      menu()?.repeated_orders_consumers
    "
  >
    <h2 class="mat-h2 order-saved">
      <div class="icon-wrapper">
        <div [class]="pulsateIcon ? 'pulsing-button' : ''"></div>
        <mat-icon class="send-icon">send</mat-icon>
      </div>
      <br />
      {{ 'orders.confirm.order-saved' | transloco }}
    </h2>
    @if (ordersFetched() && orders()?.length > 0) {
      @if (manageRepeatOrders()) {
        <p class="mat-body order-detail">
          <win-repeat-order
            class="repeat-all repeat-menu-order"
            [checkin]="(consumer() || editedItem())?.checkin"
            [checkout]="(consumer() || editedItem())?.checkout"
            [disabledRepeatOptions]="disabledRepeatOptions()"
            [fabButton]="true"
            [highlightGlobalRepeat]="highlightGlobalRepeat"
            [isOffline]="isOffline()"
            [lang]="lang()"
            [preselect]="false"
            [repeatOrders]="repeatOrders()"
            [repeatOrdersAllType]="repeatOrdersAllType"
            [repeatOrdersEquivalentToOrders]="repeatOrdersEquivalentToOrders"
            (selectionChanged)="globalRepeatChanged($event)"
          ></win-repeat-order>
        </p>
      } @else if (menu()?.repeated_orders_consumers) {
        <p class="mat-body order-detail">
          <button
            mat-fab
            extended
            color="primary"
            class="repeat-all"
            [class.selected]="
              highlightGlobalRepeat ||
              (repeatOrders()?.length &&
                repeatOrdersEquivalentToOrders &&
                repeatOrdersEquivalentToOrdersIsRepeatedDailyFrom)
            "
            (click)="
              repeatAllOrdersConsumer(
                highlightGlobalRepeat ||
                  (repeatOrders()?.length &&
                    repeatOrdersEquivalentToOrders &&
                    repeatOrdersEquivalentToOrdersIsRepeatedDailyFrom)
              )
            "
          >
            <mat-icon>
              {{
                highlightGlobalRepeat ||
                (repeatOrders()?.length &&
                  repeatOrdersEquivalentToOrders &&
                  repeatOrdersEquivalentToOrdersIsRepeatedDailyFrom)
                  ? 'event'
                  : 'repeat'
              }}
            </mat-icon>
            @if (
              highlightGlobalRepeat ||
              (repeatOrders()?.length &&
                repeatOrdersEquivalentToOrders &&
                repeatOrdersEquivalentToOrdersIsRepeatedDailyFrom)
            ) {
              {{ 'orders.confirm.repeat-all-consumer-active' | transloco }}
            } @else {
              {{ 'orders.confirm.repeat-all-consumer' | transloco }}
            }
          </button>
        </p>
      }
    }
    <p class="mat-body order-detail">
      @if (menu()) {
        {{
          menu()?.show_date
            ? ('orders.confirm.daily-menu' | transloco)
            : menu()?.name
        }}
      } @else if (orders()?.length && orders()[0].menu_detail) {
        {{
          orders()[0].menu_detail.dates?.length
            ? ('orders.confirm.daily-menu' | transloco)
            : orders()[0].menu_detail?.name
        }}
      }
      @if (menuDate || getSelectedDiets(orders()[0].diets_detail).length > 1) {
        <span>-</span>
      }
      {{ menuDate | date: 'EEEE d MMMM yyyy ' : undefined : lang() }}
      @if (orders()?.length && orders()[0].diets_detail?.length) {
        <span> <br />({{ getSelectedDiets(orders()[0].diets_detail) }}) </span>
      }
    </p>
  </div>
}
@if (ordersFetched()) {
  <div class="orders-wrapper">
    <h2 class="mat-h2">
      @if (orders()?.length > 0) {
        {{ 'orders.confirm.title' | transloco }}
      } @else {
        {{ 'orders.confirm.title-empty' | transloco }}
      }
    </h2>

    @if (!!orders()?.length) {
      <mat-card class="orders-card mat-elevation-z2">
        @for (order of orders(); track order.id; let i = $index) {
          <win-selected-order
            class="mat-body order"
            [checkin]="(consumer() || editedItem())?.checkin"
            [checkout]="(consumer() || editedItem())?.checkout"
            [disabledRepeatOptions]="disabledRepeatOptions()"
            [isDailyMenu]="menu()?.show_date"
            [isOffline]="isOffline()"
            [lang]="lang()"
            [order]="order"
            [orderPrev]="orders()[i - 1]"
            [repeatOrder]="
              repeatOrdersDict[
                datelessMenu ? order.section_level1_baselang : ''
              ]?.[order.section_level2_baselang]?.[
                order.section_level3_baselang
              ]?.[order.item_baselang]
            "
            [showRepeat]="manageRepeatOrders()"
            (repeatChanged)="repeatChanged($event, order)"
          ></win-selected-order>
        }
      </mat-card>
    }
  </div>
} @else {
  <div class="skeleton-wrapper">
    @for (i of [0, 1, 2, 3]; track i) {
      <ngx-skeleton-loader
        class="skeleton order"
        [theme]="{ height: '100%' }"
      ></ngx-skeleton-loader>
    }
  </div>
}

<div class="confirm-actions">
  <span
    [matTooltip]="
      !canCancel ? ('orders.confirm.tooltip-cannot-cancel' | transloco) : ''
    "
  >
    @if (!!orders()?.length) {
      <button
        mat-button
        class="cancel-order"
        color="warn"
        [disabled]="!canCancel"
        (click)="cancelOrder()"
      >
        <span class="text">{{ 'orders.cancel-order' | transloco }}</span>
      </button>
    }
  </span>
  @if (goBackPage()) {
    <button
      mat-button
      color="primary"
      class="win-button-light"
      (click)="addAnotherOrder()"
    >
      <span class="text">{{ 'orders.add-another-order' | transloco }}</span>
    </button>
  }
  <button mat-fab extended color="primary" (click)="toMenus()">
    {{
      (goBackPage() && goBackTranslations[goBackPage()]
        ? goBackTranslations[goBackPage()]
        : 'orders.confirm.done'
      ) | transloco
    }}
  </button>
</div>

@if (manageRepeatOrders() && inactiveOrders?.length) {
  <div class="repeated-orders-container">
    <div class="toggle-inactive">
      <h4>
        <mat-icon class="inactive-icon">warning</mat-icon>
        <strong class="inactive-count">{{ inactiveOrders?.length }}</strong>
        {{ 'orders.confirm.inactive-repeated.title' | transloco }}
      </h4>
      <button
        class="toggle-button"
        mat-flat-button
        (click)="toggleInactiveRepeated()"
      >
        {{
          hideInactiveRepeatedOrders
            ? ('orders.confirm.inactive-repeated.show' | transloco)
            : ('orders.confirm.inactive-repeated.hide' | transloco)
        }}
      </button>
    </div>
    @if (!hideInactiveRepeatedOrders) {
      <div class="orders-card">
        @for (
          repeatOrder of inactiveOrders;
          track repeatOrder.id;
          let i = $index
        ) {
          <win-selected-order
            class="mat-body order"
            [checkin]="(consumer() || editedItem())?.checkin"
            [checkout]="(consumer() || editedItem())?.checkout"
            [disabledRepeatOptions]="disabledRepeatOptions()"
            [isDailyMenu]="menu()?.show_date"
            [isOffline]="isOffline()"
            [lang]="lang()"
            [order]="repeatOrder"
            [orderPrev]="orders()[i - 1]"
            [repeatOrder]="repeatOrder"
            [showRepeat]="true"
            [subtleUI]="true"
            (repeatChanged)="repeatChangedNotOrdered($event, repeatOrder)"
          ></win-selected-order>
        }
      </div>
    }
  </div>
}
