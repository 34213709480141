<mat-card appearance="outlined" class="mat-body card mat-elevation-z2">
  <win-form-with-errors
    class="form"
    [formGroup]="loginForm"
    formId="simple-login"
  >
    <div class="fields">
      <win-field-with-errors
        class="field"
        icon="email"
        type="email"
        [autocomplete]="'email'"
        [label]="'registration.email.placeholder' | transloco"
        translationKey="registration.email.errors"
        formControlName="email"
        [required]="true"
      ></win-field-with-errors>
      <win-field-with-errors
        class="field"
        icon="lock"
        type="password"
        [autocomplete]="'current-password'"
        [label]="'registration.password1.placeholder' | transloco"
        formControlName="password"
        translationKey="registration.password1.errors"
        [required]="true"
      ></win-field-with-errors>
    </div>
    <button
      class="submit-button"
      data-cy="submit-login"
      mat-flat-button
      color="primary"
      type="submit"
      (click)="onSubmit()"
      [disabled]="spinnerState()"
    >
      @if (spinnerState()) {
        <span>
          <mat-spinner
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="24"
          ></mat-spinner>
        </span>
      }
      {{ 'login.login-button' | transloco }}
    </button>
  </win-form-with-errors>
</mat-card>

<div class="links mat-body">
  <a data-cy="remind-password-link" routerLink="/reset-password">{{
    'app.links.remind-password' | transloco
  }}</a>
  <a data-cy="register-link" routerLink="/registration">{{
    'app.links.register' | transloco
  }}</a>
</div>
