import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoPipe } from '@jsverse/transloco';
import { ReportFormat, SupportedReportFormats } from '../../models/user';

@Component({
  selector: 'win-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslocoPipe,
  ],
})
export class DownloadReportComponent {
  readonly loading = input.required<boolean>();
  readonly format = input.required<SupportedReportFormats>();
  readonly hideDigital = input(false);
  readonly name = input.required<string>();
  readonly nameItems = input<string>();
  readonly totalCount = input<number>();
  readonly innerHTML = input<string>();

  readonly downloadReport = output<ReportFormat | undefined>();
  readonly openReport = output<void>();
}
