import {
  Component,
  DestroyRef,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  input,
  viewChild,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { delay, filter, switchMap, tap } from 'rxjs/operators';
import { clearFormErrors } from 'src/app/redux/global/global.actions';
import { NON_FIELD_ERRORS } from 'src/app/shared/directives/server-form-error.directive';
import { Order } from 'src/app/shared/models/orders';
import { TranslocoPipe } from '@jsverse/transloco';
import { SelectedOrderComponent } from '../../orders-complete/confirm-orders/selected-order/selected-order.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DatePipe } from '@angular/common';
import { FieldWithErrorsComponent } from '../../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../../shared/components/form-with-errors/form-with-errors.component';
import { MatCardModule } from '@angular/material/card';
import { PrivacyLevel } from 'src/app/shared/models/user';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { globalFeature } from 'src/app/redux/global/global.state';

@Component({
  selector: 'win-live-order-view',
  templateUrl: './live-order-view.component.html',
  styleUrls: ['./live-order-view.component.scss'],
  imports: [
    MatCardModule,
    FormWithErrorsComponent,
    ReactiveFormsModule,
    FieldWithErrorsComponent,
    MatButtonModule,
    MatIconModule,
    SelectedOrderComponent,
    DatePipe,
    TranslocoPipe,
  ],
})
export class LiveOrderViewComponent implements OnChanges, OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);

  readonly lang = input.required<InterfaceLanguage>();
  readonly loading = input(false);
  readonly orders = input.required<Order[]>();
  readonly ordersFetched = input.required<boolean>();
  readonly ordersRemainingCount = input.required<number>();
  readonly ordersDoneCount = input.required<number>();
  readonly privacy = input.required<PrivacyLevel>();
  readonly fetchOrdersCount = output<void>();
  readonly rfidChanged = output<string>();

  readonly audioSuccessRef =
    viewChild<ElementRef<HTMLAudioElement>>('audioSuccess');
  readonly audioFailRef = viewChild<ElementRef<HTMLAudioElement>>('audioFail');
  readonly audioClickRef =
    viewChild<ElementRef<HTMLAudioElement>>('audioClick');

  readonly rfidCardRef = viewChild<ElementRef<HTMLElement>>('rfidCard');

  terminalForm = new FormGroup({
    rfid: new FormControl('', [Validators.required]),
  });
  today = new Date();

  ngOnChanges(changes: SimpleChanges): void {
    const orders = this.orders();
    if ('orders' in changes && orders) {
      this.resetRfid();
      if (this.ordersFetched()) {
        if (orders.length) {
          if ('ordersRemainingCount' in changes) {
            this.audioSuccessRef().nativeElement.play();
          } else {
            this.audioClickRef().nativeElement.play();
          }
        } else {
          this.audioFailRef().nativeElement.play();
          this.rfidCardRef().nativeElement.classList.add('shake');
          setTimeout(() => {
            this.rfidCardRef().nativeElement.classList.remove('shake');
          }, 1000);
        }
      }
    }
  }

  ngOnInit(): void {
    this.fetchOrdersCount.emit();
    this.store
      .pipe(select(globalFeature.selectFormErrors))
      .pipe(
        filter((error) => !!error?.[NON_FIELD_ERRORS]),
        tap(() => {
          this.terminalForm.reset({ rfid: '' }, { emitEvent: false });
        }),
        switchMap(() => of(null).pipe(delay(10000))),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.terminalForm.updateValueAndValidity();
        this.store.dispatch(clearFormErrors({ payload: NON_FIELD_ERRORS }));
      });
  }

  initTerminalLogin(): void {
    if (this.terminalForm.valid && this.terminalForm.controls.rfid.value) {
      this.rfidChanged.emit(this.terminalForm.controls.rfid.value);
    } else {
      this.terminalForm.reset();
    }
  }

  resetRfid(): void {
    this.terminalForm.reset();
    this.terminalForm.updateValueAndValidity();
    this.terminalForm.controls[`rfid`].setErrors(null);
  }
}
