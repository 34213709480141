@if (type() !== 'checkbox') {
  <mat-form-field
    class="form-field"
    [subscriptSizing]="subscriptSizing()"
    [attr.data-cy]="formControlName()"
  >
    @if (icon()) {
      <mat-icon
        [attr.aria-label]="label() ?? placeholder() ?? icon()"
        matIconPrefix
        >{{ icon() }}</mat-icon
      >
    }
    <mat-label>{{ label() ?? placeholder() ?? '' }}</mat-label>

    @switch (type()) {
      @case ('select') {
        <mat-select
          serverFieldError
          [attr.name]="formControlName()"
          [controlName]="formControlName()"
          [formControl]="control"
          [required]="required()"
          [multiple]="multiple()"
          (input)="onInputChange()"
          (selectionChange)="selectionChange($event)"
        >
          @for (
            option of selectOptions();
            track valueField()
              ? option?.[valueField()]
              : option?.id !== undefined
                ? option?.id
                : option
          ) {
            <mat-option
              [value]="
                valueField()
                  ? option?.[valueField()]
                  : option?.id !== undefined
                    ? option?.id
                    : option
              "
              >{{
                optionLabel()
                  ? (optionLabel() +
                      '.' +
                      (optionLabelMulti()
                        ? (option?.id !== undefined ? option?.id : option) *
                          optionLabelMulti()
                        : option?.id !== undefined
                          ? option?.id
                          : option) | transloco)
                  : option?.name || option?.[lang()] || getFallbackLang(option)
              }}</mat-option
            >
          }
        </mat-select>
      }

      @case ('date') {
        <input
          (click)="picker.open()"
          matInput
          #datepickerInput
          [name]="formControlName()"
          [matDatepicker]="picker"
          [formControl]="control"
          (dateChange)="changeDate($event)"
          (input)="onInputChange()"
        />
        <mat-datepicker #picker></mat-datepicker>
      }

      @case ('time') {
        <input
          matInput
          placeholder="12:00"
          [name]="formControlName()"
          [formControl]="control"
          (input)="onInputChange()"
        />
      }

      @case ('textarea') {
        <textarea
          matInput
          cols="30"
          rows="2"
          serverFieldError
          class="textarea"
          [name]="formControlName()"
          [controlName]="formControlName()"
          [formControl]="control"
          [required]="required()"
          (input)="onInputChange()"
        ></textarea>
      }

      @default {
        <input
          #inputElement
          matInput
          serverFieldError
          [attr.autocomplete]="autocomplete() ? autocomplete() : null"
          [name]="formControlName()"
          [controlName]="formControlName()"
          [formControl]="control"
          [required]="required()"
          [type]="type()"
          (blur)="blur($event)"
          (input)="onInputChange()"
        />
      }
    }

    @if (showProgressBar()) {
      <mat-progress-bar class="progress-bar" mode="query"></mat-progress-bar>
    }
    @if (control?.errors) {
      <mat-error>
        @for (error of control?.errors | keyvalue; track error.key) {
          <span>{{
            error.key === 'error'
              ? error.value
              : (translationKey() + '.' + error.key | transloco)
          }}</span>
        }
      </mat-error>
    }

    <span matIconSuffix>
      @if (type() === 'date') {
        <button
          mat-icon-button
          type="button"
          [attr.aria-label]="'shared.choose-date' | transloco"
          (click)="openDatepicker()"
        >
          <mat-icon>event</mat-icon>
        </button>
      }
      @if (type() === 'time') {
        <ng-content select=".time-picker-content"></ng-content>
      }
      @if (suffixIcon()) {
        <mat-icon [attr.aria-label]="suffixIcon()">{{ suffixIcon() }}</mat-icon>
      } @else {
        <ng-content select=".suffixTemplate"></ng-content>
      }
    </span>
  </mat-form-field>
} @else {
  <mat-checkbox
    color="primary"
    class="checkbox"
    [formControl]="control"
    (input)="onInputChange()"
    >{{ placeholder() }}</mat-checkbox
  >
}
