import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { Menu } from 'src/app/shared/models/menus';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';

@Component({
  selector: 'win-save-and-go',
  templateUrl: './save-and-go.component.html',
  styleUrls: ['./save-and-go.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    DatePipe,
    TranslocoPipe,
  ],
})
export class SaveAndGoComponent {
  readonly menu = input.required<Menu>();
  readonly disabled = input.required<boolean>();
  readonly arrowForward = input.required<boolean>();
  readonly lang = input.required<InterfaceLanguage>();
  readonly sameDay = input(false);

  readonly btnClicked = output();
}
