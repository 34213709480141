<mat-card appearance="outlined" class="courses-card success-gradient">
  <div class="card-title">
    <h3 class="report-title mat-body-strong">
      <a [attr.href]="course()['url_' + lang()]" target="_blank">{{
        course()[lang()]
      }}</a>
    </h3>
    @for (location of course().locations_detail; track location) {
      <span
        class="mat-caption location-chip"
        [attr.title]="location?.acronym ? location?.name : ''"
      >
        {{ location?.acronym || location?.name }}
      </span>
    }
  </div>
  <div class="button-container">
    <a
      class="link-button"
      mat-fab
      extended
      [attr.href]="course()['url_' + lang()]"
      target="_blank"
    >
      <mat-icon>{{ course().icon }}</mat-icon>
      {{ 'menus.build-new-skills.open-link' | transloco }}
    </a>
  </div>
</mat-card>

@if (isManager()) {
  <div class="action-buttons">
    <button
      mat-button
      routerLink="/manage/course/{{ course().id }}"
      (click)="editCourse.emit(course())"
      color="primary"
      class="edit-link"
    >
      {{ 'menus.build-new-skills.edit-link' | transloco }}
    </button>
    <button
      mat-button
      [matMenuTriggerFor]="deleteCourseMenu"
      [matMenuTriggerData]="{ courseId: course().id }"
      class="win-button-warning delete-link"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
}

<mat-menu #deleteCourseMenu="matMenu">
  <ng-template matMenuContent let-courseId="courseId">
    <span mat-menu-item>
      {{ 'menus.build-new-skills.delete-course.title' | transloco }}
    </span>
    <button
      class="delete-course-button"
      mat-menu-item
      (click)="deleteCourse.emit(courseId)"
    >
      <mat-icon class="delete-course-icon">delete</mat-icon>
      {{ 'menus.build-new-skills.delete-course.yes' | transloco }}
    </button>
  </ng-template>
</mat-menu>
