import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { Course } from 'src/app/shared/models/menus';

@Component({
  selector: 'win-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    MatMenuModule,
    TranslocoPipe,
  ],
})
export class CourseComponent {
  readonly course = input.required<Course>();
  readonly isManager = input.required<boolean>();
  readonly lang = input.required<string>();

  readonly deleteCourse = output<number>();
  readonly editCourse = output<Course>();
}
