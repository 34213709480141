import { UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  input,
  output,
  signal,
} from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@jsverse/transloco';
import { LANGUAGES } from '../../../shared/constants/languages';

@Component({
  selector: 'win-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,
    TranslocoPipe,
    UpperCasePipe,
  ],
})
export class LanguagePickerComponent implements OnDestroy, OnInit {
  currentLang = input.required<string>();
  isOffline = input.required<boolean>();
  languageChange = output<keyof typeof LANGUAGES>();

  langs = Object.keys(LANGUAGES);
  small = signal<boolean>(window.innerWidth <= 500);
  private resizeListener: () => void;

  ngOnInit() {
    this.resizeListener = () => this.small.set(window.innerWidth <= 500);
    window.addEventListener('resize', this.resizeListener);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  }
}
