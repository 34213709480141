import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Report, ReportType } from 'src/app/shared/models/user';
import { ReportActions } from './report.actions';

export interface State extends EntityState<Report> {
  loading: boolean;
}

export const adapter: EntityAdapter<Report> = createEntityAdapter<Report>();

export const initialState: State = adapter.getInitialState({
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(ReportActions.setReports, (state, action) =>
    adapter.setAll(action.reports, state),
  ),
  on(ReportActions.clearReports, (state) => adapter.removeAll(state)),
  on(ReportActions.updateReport, (state, action) =>
    adapter.updateOne(action.report, state),
  ),
);

export const reportsFeature = createFeature({
  name: 'reports',
  reducer,
  extraSelectors: ({ selectReportsState }) => ({
    ...adapter.getSelectors(selectReportsState),
    selectReportByType: (type: ReportType) =>
      createSelector(
        adapter.getSelectors(selectReportsState).selectAll,
        (reports) => reports.filter((report) => report.type === type),
      ),
    selectReportsDownloading: createSelector(
      adapter.getSelectors(selectReportsState).selectAll,
      (reports) =>
        reports
          .filter((report) => report.downloading)
          .map((report) => report.name),
    ),
  }),
});
