import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as Sentry from '@sentry/angular';

import {
  catchError,
  filter,
  map,
  mergeMap,
  retry,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, of, throwError } from 'rxjs';
import { ReportActions } from './report.actions';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { API_CONFIG } from 'src/app/app.config';
import { downloadFile } from 'src/app/shared/utils.functions';
import { authenticationFeature } from '../authentication/authentication.state';
import { handleHttpError } from '../global/global.actions';
import { globalFeature } from '../global/global.state';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user/user.service';
import { usersFeature } from '../user/user.state';

@Injectable()
export class ReportEffects {
  private readonly actions$ = inject(Actions);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly userService = inject(UserService);

  fetchReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportActions.fetchReports),
      withLatestFrom(
        this.store.pipe(select(usersFeature.selectEmailVerified)),
        this.store.pipe(select(authenticationFeature.selectIsSimpleAuth)),
      ),
      filter(([, isVerified, isSimpleAuth]) => isVerified && !isSimpleAuth),
      tap(() =>
        this.store.dispatch(ReportActions.setLoading({ loading: true })),
      ),
      switchMap(() =>
        this.userService.getReports().pipe(
          mergeMap((reports) => [
            ReportActions.setReports({ reports }),
            ReportActions.setLoading({ loading: false }),
          ]),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  downloadReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportActions.downloadReport),
      withLatestFrom(this.store.select(globalFeature.selectLanguage)),
      tap(([{ report }]) =>
        this.store.dispatch(
          ReportActions.updateReport({
            report: {
              id: report.id,
              changes: { downloading: true },
            },
          }),
        ),
      ),
      mergeMap(([{ report, data }, lang]) => {
        // Remove page_size and page parameters from the data (not supported by reports)
        const { page_size, page, ...filteredData } = data;
        return this.userService
          .downloadReport(lang, report.name, filteredData)
          .pipe(
            retry({
              count: 1,
              delay: (error: unknown) => {
                // Only retry if it's not a 500 error
                if ((error as HttpErrorResponse).status !== 500) {
                  return of(error);
                }
                return throwError(() => error);
              },
            }),
            map((res) => {
              if (res == undefined)
                throw new HttpErrorResponse({
                  error: 'Timeout',
                  status: 400,
                }) as Error;
              return res;
            }),
            mergeMap((res: HttpResponse<Blob>) => {
              downloadFile(res);
              return [
                ReportActions.updateReport({
                  report: {
                    id: report.id,
                    changes: { downloading: false },
                  },
                }),
              ];
            }),
            catchError((error: unknown) => {
              const exception =
                ((error as HttpErrorResponse).error as string) ||
                (error as HttpErrorResponse).message ||
                error;
              if (exception instanceof Error) {
                Sentry.captureException(exception);
              } else {
                const newError = new Error(
                  `Failed to load ${JSON.stringify(error)}`,
                );
                Sentry.captureException(newError);
              }
              return [
                handleHttpError({ error }),
                ReportActions.updateReport({
                  report: {
                    id: report.id,
                    changes: { downloading: false },
                  },
                }),
              ];
            }),
          );
      }),
    ),
  );

  openReport$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReportActions.viewReport),
        withLatestFrom(this.store.select(globalFeature.selectLanguage)),
        switchMap(([{ report, params }, lang]) => {
          let url = `${API_CONFIG.orderTakingApi}/reports/render/?report=${report}&lang=${lang}`;
          Object.keys(params ?? {})
            .filter(
              (k) =>
                params[k] &&
                (k !== 'diets' || !!params[k]) &&
                !['page_size', 'page', 'ordering', 'diets'].includes(k),
            )
            .forEach((key: string) => {
              if (key === 'ids') {
                (params[key] as number[]).forEach((id) => {
                  url += `&${key}=${id}`;
                });
              } else {
                url += `&${key}=${params[key]}`;
              }
            });
          this.router.navigate([`redirect`], {
            state: { newTab: true },
            queryParams: { next: encodeURIComponent(url) },
          });
          return EMPTY;
        }),
        catchError((error: unknown) => {
          const exception =
            ((error as HttpErrorResponse).error as string) ||
            (error as HttpErrorResponse).message ||
            error;
          if (exception instanceof Error) {
            Sentry.captureException(exception);
          } else {
            const newError = new Error(
              `Failed to load ${JSON.stringify(error)}`,
            );
            Sentry.captureException(newError);
          }
          return [handleHttpError({ error })];
        }),
      ),
    { dispatch: false },
  );
}
