import { Component } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';

@Component({
  selector: 'win-auto-logout',
  templateUrl: './auto-logout.dialog.html',
  styleUrls: ['./auto-logout.dialog.scss'],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    TranslocoPipe,
  ],
})
export class AutoLogoutDialog {
  time: number;
}
