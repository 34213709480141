@if (terminalType() !== 2) {
  <mat-card class="card mat-elevation-z2" data-cy="terminal-login">
    <img class="card-img-top" src="img/menusCard3.png" alt="" />
    <p class="mat-body subtitle">{{ 'login.title' | transloco }}</p>
    <win-form-with-errors
      class="form"
      [formGroup]="terminalForm"
      [formId]="terminalFormId"
    >
      <div class="fields">
        <win-field-with-errors
          class="identifier-field"
          type="text"
          [label]="'login.terminal.placeholder' | transloco"
          translationKey="login.terminal.errors"
          [formControlName]="controlName"
          [showProgressBar]="loading()"
          [autofocus]="true"
          [autoclear]="true"
          [autocomplete]="'off'"
          [required]="true"
        >
          <button
            class="suffixTemplate"
            mat-icon-button
            (click)="triggerTerminalLogin()"
          >
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </win-field-with-errors>
      </div>
    </win-form-with-errors>
  </mat-card>
} @else {
  <mat-card class="card mat-elevation-z2" data-cy="terminal-login">
    <img class="card-img-top" src="img/menusCard3.png" alt="" />
    <p class="mat-body-strong title">{{ 'login.title' | transloco }}</p>
    <win-form-with-errors
      class="form"
      [formGroup]="consumerForm"
      [formId]="consumerFormId"
    >
      <div class="fields">
        <win-field-with-errors
          class="room-number"
          type="text"
          [label]="'login.terminal.consumer-form.room' | transloco"
          translationKey="login.terminal.consumer-room-errors"
          [autocomplete]="'off'"
          [formControlName]="'room'"
          [showProgressBar]="roomDetailsLoading()"
          [required]="true"
        >
          @if (consumerForm.controls.room.value) {
            <button
              class="suffixTemplate"
              mat-icon-button
              type="button"
              (click)="clearRoom()"
            >
              <mat-icon>clear</mat-icon>
            </button>
          }
        </win-field-with-errors>
      </div>
    </win-form-with-errors>
  </mat-card>
}

@if (showCodeInput) {
  @if (
    !userIsTyping &&
    !roomDetailsLoading() &&
    consumerForm.controls.room.value &&
    consumerForm.valid
  ) {
    <mat-card class="card consumer-card mat-elevation-z2">
      @if (!aggregatedOrderTakingEnabled()) {
        <div class="title">
          {{ 'login.terminal.consumer-title.full-name-title' | transloco }}
        </div>
      }
      @if (aggregatedOrderTakingEnabled()) {
        <div class="title">
          {{ 'login.terminal.consumer-title.name-title' | transloco }}
        </div>
      }
      <win-form-with-errors
        [formGroup]="consumerForm"
        [formId]="consumerFormId"
      >
        <div class="fields">
          @if (aggregatedOrderTakingEnabled()) {
            <ngx-otp-input
              #nameCodeInput
              [options]="otpInputConfig"
              (otpComplete)="onNameCodeCompleted($event)"
            ></ngx-otp-input>
            <p
              class="example-text"
              [innerHTML]="
                'login.terminal.consumer-form.name_example' | transloco
              "
            ></p>
          } @else {
            <p class="label">
              {{ 'login.terminal.consumer-form.first_name' | transloco }}:
            </p>
            <ngx-otp-input
              #firstNameCodeInput
              [options]="otpInputConfig"
              (otpComplete)="onCodeCompleted($event, 'first_name')"
            ></ngx-otp-input>
            <p
              class="example-text"
              [innerHTML]="
                'login.terminal.consumer-form.first_name_example' | transloco
              "
            ></p>
            @if (showLastName) {
              <p class="label">
                {{ 'login.terminal.consumer-form.last_name' | transloco }}:
              </p>
              <ngx-otp-input
                #lastNameCodeInput
                [options]="otpInputConfig"
                (otpComplete)="onCodeCompleted($event, 'last_name')"
              ></ngx-otp-input>
              <p
                class="example-text"
                [innerHTML]="
                  'login.terminal.consumer-form.last_name_example' | transloco
                "
              ></p>
            }
          }
        </div>
      </win-form-with-errors>
    </mat-card>
  }
} @else {
  @if (
    !userIsTyping &&
    !roomDetailsLoading() &&
    roomDetailsList &&
    consumerForm.controls.room.value
  ) {
    <mat-card class="card consumer-card mat-elevation-z2">
      <div class="mat-body">
        {{ 'login.terminal.consumer-title.title' | transloco }}
      </div>
      @for (consumer of roomDetailsList; track consumer) {
        <div>
          <div class="consumer">
            <div class="name-vip" [class.is-vip]="consumer.vip">
              @if (consumer.vip) {
                <mat-icon
                  title="{{ 'manage-accesses.table.headers.vip' | transloco }}"
                  class="vip-color"
                  >local_activity</mat-icon
                >
              }
              {{ consumer.name }}
            </div>
            <div>
              <button
                (click)="initConsumerTerminalLogin(consumer.uuid)"
                color="primary"
                class="select-consumer"
                mat-flat-button
              >
                {{ 'login.terminal.consumer-form.select' | transloco }}
              </button>
            </div>
          </div>
          <hr />
        </div>
      }
    </mat-card>
  }
}
