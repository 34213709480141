import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { UnknownManagableItem } from '../../shared/models/misc';
import { Order, RepeatOrder } from '../../shared/models/orders';
import { PrivacyLevel } from '../../shared/models/user';
import { redirectToOrders } from '../menus/menus.actions';
import { menusFeature } from '../menus/menus.state';
import { selectRouteParams } from '../router/router.selectors';
import * as OrderActions from './orders.actions';
import { usersFeature } from '../user/user.state';

interface OrdersState {
  source: string | null;
  diets: string[];
  orders: EntityState<Order>;
  ordersSelectionChanged: boolean;
  routerExtras: UnknownManagableItem;
  repeatOrders: RepeatOrder[];
  repeatOrdersManage: EntityState<RepeatOrder>;
  ordersFetched: boolean;
  save_order_spinner: boolean;
  productSheetLoading: boolean;
  productSheetSource: string;
  menuLanguage: string;
  ordersRemainingCount: number;
  ordersDoneCount: number;
}

const adapter: EntityAdapter<Order> = createEntityAdapter<Order>();
const adapterRepeat: EntityAdapter<RepeatOrder> =
  createEntityAdapter<RepeatOrder>();

const initialState: OrdersState = {
  orders: adapter.getInitialState<Order>(null),
  source: null,
  diets: [],
  ordersSelectionChanged: null,
  routerExtras: null,
  repeatOrders: null,
  repeatOrdersManage: adapterRepeat.getInitialState<RepeatOrder>(null),
  ordersFetched: false,
  save_order_spinner: false,
  productSheetLoading: false,
  productSheetSource: null,
  menuLanguage: null,
  ordersRemainingCount: null,
  ordersDoneCount: null,
};

const reducer = createReducer<OrdersState>(
  initialState,
  on(
    OrderActions.sendOrdersAndPrev,
    OrderActions.sendOrdersAndNext,
    (state) => ({
      ...state,
      source: null,
    }),
  ),
  on(OrderActions.repeatOrdersFetched, (state, { repeatOrders }) => ({
    ...state,
    repeatOrders,
  })),
  on(OrderActions.setRepeatOrdersManage, (state, { repeatOrders }) => ({
    ...state,
    repeatOrdersManage: adapterRepeat.setAll(
      repeatOrders,
      state?.repeatOrdersManage,
    ),
  })),
  on(OrderActions.setCreatedRepeatOrder, (state, { repeatOrder }) => ({
    ...state,
    repeatOrdersManage: adapterRepeat.addOne(
      repeatOrder,
      state?.repeatOrdersManage,
    ),
  })),
  on(OrderActions.setUpdatedRepeatOrder, (state, { repeatOrder }) => ({
    ...state,
    repeatOrdersManage: adapterRepeat.updateOne(
      repeatOrder,
      state?.repeatOrdersManage,
    ),
  })),
  on(OrderActions.setDeletedRepeatOrder, (state, { id }) => ({
    ...state,
    repeatOrdersManage: adapterRepeat.removeOne(id, state?.repeatOrdersManage),
  })),
  on(OrderActions.setSource, (state, { source }) => ({
    ...state,
    source,
  })),
  on(OrderActions.setProductSheetSource, (state, { productSheetSource }) => ({
    ...state,
    productSheetSource,
  })),
  on(OrderActions.setSaveOrderButtonState, (state, { save_order_spinner }) => ({
    ...state,
    save_order_spinner,
  })),
  on(OrderActions.setProductSheetLoading, (state, { productSheetLoading }) => ({
    ...state,
    productSheetLoading,
  })),
  on(OrderActions.setMenuDiets, (state, { selectedDiets }) => ({
    ...state,
    diets: selectedDiets,
  })),
  on(OrderActions.setOrders, (state, { orders }) => ({
    ...state,
    orders: adapter.setAll(orders, state?.orders),
    ordersFetched: true,
  })),
  on(OrderActions.ordersSelectionStateChanged, (state, { state: payload }) => ({
    ...state,
    ordersSelectionChanged: payload,
  })),
  on(redirectToOrders, (state) => ({
    ...state,
  })),
  on(OrderActions.setManagableItem, (state, { item }) => ({
    ...state,
    routerExtras: item,
  })),
  on(OrderActions.setMenuLanguage, (state, { language }) => ({
    ...state,
    source: null,
    menuLanguage: language,
  })),
  on(OrderActions.setOrdersCount, (state, { count }) => ({
    ...state,
    ordersRemainingCount: count,
  })),
  on(OrderActions.setOrdersDoneCount, (state, { count }) => ({
    ...state,
    ordersDoneCount: count,
  })),
  on(OrderActions.setOrdersCountIncrement, (state) => ({
    ...state,
    ordersRemainingCount: state.ordersRemainingCount - 1,
    ordersDoneCount: state.ordersDoneCount + 1,
  })),
  on(OrderActions.updateRepeatOrders, (state, { repeatOrders }) => ({
    ...state,
    repeatOrders: state.repeatOrders?.map((repeatOrder) => {
      if (
        repeatOrders.some(
          (existingOrder) =>
            existingOrder.item_baselang === repeatOrder.item_baselang,
        )
      ) {
        return repeatOrders.find(
          (existingOrder) =>
            existingOrder.item_baselang === repeatOrder.item_baselang,
        );
      }
      return repeatOrder;
    }),
  })),
  on(OrderActions.clearOrdersPage, () => initialState),
);

export const ordersFeature = createFeature({
  name: 'orders',
  reducer,
  extraSelectors: ({
    selectOrders,
    selectRepeatOrdersManage,
    selectRouterExtras,
  }) => ({
    selectOrderEntities: createSelector(
      selectOrders,
      adapter.getSelectors().selectAll,
    ),
    selectFirstOrderOfMenu: createSelector(selectOrders, (orders) =>
      adapter.getSelectors().selectTotal(orders)
        ? adapter.getSelectors().selectAll(orders)[0]
        : null,
    ),
    selectRepeatOrdersManage: createSelector(
      selectRepeatOrdersManage,
      adapterRepeat.getSelectors().selectAll,
    ),
    selectDate: createSelector(
      menusFeature.selectCurrentMenu,
      menusFeature.selectSelectedDate,
      selectRouteParams,
      (menu, pickedDate, params) =>
        menu?.date || pickedDate || (params?.date as string),
    ),
    selectShowHiddenOrders: createSelector(
      usersFeature.selectIsManager,
      usersFeature.selectIsAgent,
      usersFeature.selectCurrentUser,
      selectRouterExtras,
      (isManager, isAgent, user, extras) => {
        const agentAdminItems = user?.location
          ? user?.location_detail?.agent_show_admin_items
          : user?.organisation.agent_show_admin_items;
        return (
          (isManager && !!extras) || (isAgent && !!extras && agentAdminItems)
        );
      },
    ),
    selectManageRepeatOrders: createSelector(
      usersFeature.selectIsManager,
      usersFeature.selectIsAgent,
      usersFeature.selectIsTerminal,
      usersFeature.selectCurrentUser,
      selectRouterExtras,
      (isManager, isAgent, isTerminal, user, extras) => {
        const agentManageRepeatOrders = user?.location
          ? user?.location_detail?.agent_manage_repeat_orders
          : user?.organisation.agent_manage_repeat_orders;
        return (
          (isManager || (isAgent && agentManageRepeatOrders)) &&
          (!!extras || isTerminal)
        );
      },
    ),
    selectShowConsumerInfo: createSelector(
      usersFeature.selectTerminal,
      usersFeature.selectSimpleConsumer,
      usersFeature.selectIsManager,
      usersFeature.selectIsAgent,
      selectRouterExtras,
      usersFeature.selectOrganisation,
      (term, unauth, manager, agent, item, organisation) =>
        !!(
          ((manager || agent) && (term || item)) ||
          (term && term.privacy !== PrivacyLevel.ANONYMOUS) ||
          (unauth &&
            (unauth.location_detail?.unauth_privacy !==
              PrivacyLevel.ANONYMOUS ||
              (!unauth.location &&
                organisation.unauth_privacy !== PrivacyLevel.ANONYMOUS)))
        ),
    ),
    selectShowConsumerInfoDetail: createSelector(
      usersFeature.selectTerminal,
      usersFeature.selectSimpleConsumer,
      usersFeature.selectIsManager,
      usersFeature.selectIsAgent,
      selectRouterExtras,
      usersFeature.selectOrganisation,
      (term, unauth, manager, agent, item, organisation) =>
        !!(
          ((manager || agent) && item) ||
          (term && term.privacy === PrivacyLevel.SHOW_ALL) ||
          (unauth &&
            (unauth.location_detail?.unauth_privacy === PrivacyLevel.SHOW_ALL ||
              (!unauth.location &&
                organisation.unauth_privacy === PrivacyLevel.SHOW_ALL)))
        ),
    ),
  }),
});
