import {
  AfterViewInit,
  DestroyRef,
  Directive,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { clearFormErrors } from 'src/app/redux/global/global.actions';
import { globalFeature } from 'src/app/redux/global/global.state';

@Directive({
  selector:
    '[formControl][serverFieldError], [formControlName][serverFieldError]',
  standalone: true,
})
export class ServerFieldErrorDirective implements AfterViewInit {
  private readonly controlDir = inject(NgControl, { self: true });
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);

  private serverErrorShown = false;

  readonly controlName = input.required<string>();

  ngAfterViewInit(): void {
    this.controlDir?.control?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.handleServerError();
      });
    this.store
      .pipe(
        select(globalFeature.selectFormErrors),
        filter(
          (error) => !!error?.[this.controlDir.name || this.controlName()],
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((error) => {
        this.serverErrorShown = true;
        this.controlDir.control.setErrors(
          { error: error[this.controlDir.name || this.controlName()] },
          { emitEvent: true },
        );
      });
  }

  handleServerError = () => {
    if (this.serverErrorShown) {
      this.serverErrorShown = false;
      this.store.dispatch(
        clearFormErrors({
          payload: this.controlName() || this.controlDir.name.toString(),
        }),
      );
    }
  };
}
