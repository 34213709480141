import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { fetchCourses } from 'src/app/redux/courses/courses.actions';
import { OrganisationFeatures, RoleChoice } from 'src/app/shared/models/user';

import { fetchMenus } from '../../redux/menus/menus.actions';
import {
  fetchManagableItem,
  setManagableItem,
} from '../../redux/orders/orders.actions';
import { ManagableItem, MenusParams } from '../models/misc';
import {
  getMenusParamsFromQueryParams,
  isObjectEmpty,
} from '../utils.functions';
import { ordersFeature } from 'src/app/redux/orders/orders.state';
import { usersFeature } from 'src/app/redux/user/user.state';

export const menusResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _state: RouterStateSnapshot,
) => {
  const store = inject(Store);
  const router = inject(Router);

  const managableItem = router.getCurrentNavigation().extras
    .state as ManagableItem;
  store.dispatch(setManagableItem({ item: managableItem || undefined }));

  const params = getMenusParamsFromQueryParams(route.queryParamMap);
  if (isObjectEmpty(managableItem) && !isObjectEmpty(params)) {
    store.dispatch(fetchManagableItem(params));
    return store.pipe(select(ordersFeature.selectRouterExtras)).pipe(
      filter((item) => !!item),
      take(1),
      switchMap((item: ManagableItem) => checkConditions(item, params, store)),
    );
  }
  return checkConditions(managableItem, params, store);
};

function checkConditions(
  managableItem: ManagableItem,
  params: MenusParams,
  store: Store,
): Observable<boolean> {
  return store.pipe(select(usersFeature.selectCurrentUser)).pipe(
    switchMap((user) =>
      of(user).pipe(
        withLatestFrom(
          store.pipe(
            select(
              usersFeature.selectFeature(
                OrganisationFeatures.staff_ordertaking,
              ),
            ),
          ),
          store.pipe(select(usersFeature.selectSimpleConsumer)),
          store.select(usersFeature.selectIsManagerOrAgentUser),
          store.select(usersFeature.selectIsTerminal),
        ),
      ),
    ),
    switchMap(
      ([user, staffOrdertaking, simpleConsumer, isManagerOrAgentUser]) => {
        if (
          simpleConsumer ||
          (user?.status?.email_verified &&
            (staffOrdertaking ||
              managableItem ||
              user?.terminal ||
              user?.role === RoleChoice.CONSUMER))
        ) {
          store.dispatch(
            fetchMenus({
              params: isObjectEmpty(params) ? { own: true } : params,
            }),
          );
        }
        if (
          isManagerOrAgentUser &&
          user?.status?.email_verified &&
          !managableItem
        ) {
          store.dispatch(fetchCourses());
        }
        return of(true);
      },
    ),
    take(1),
  );
}
