import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  input,
  viewChildren,
  output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  AnyAccessType,
  isManagableOrderOrConsumer,
} from 'src/app/shared/models/consumers';
import {
  CONSISTENCY_LEVELS,
  TEXTURE_LEVELS,
} from 'src/app/shared/models/diets';
import {
  Consistency,
  ManagableOrder,
  Order,
  OrderVariant,
  Texture,
} from 'src/app/shared/models/orders';

import { ProductSheetDialogComponent } from '../product-sheet-dialog/product-sheet-dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ErrorStateMatcher, MatOptionModule } from '@angular/material/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { StandardPortionSize } from 'src/app/shared/models/user';

export class ShowImmediatelyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form?.submitted;
    return !!(
      control?.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'win-order-description',
  templateUrl: './order-description.component.html',
  styleUrls: ['./order-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    TextFieldModule,
    TranslocoPipe,
  ],
})
export class OrderDescriptionComponent implements OnChanges, OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly dialog = inject(MatDialog);

  readonly autocomplete = input.required<string[] | null>();
  readonly consumer = input.required<AnyAccessType | ManagableOrder>();
  readonly disabledPortionSizes = input<StandardPortionSize[]>([]);
  readonly featureConsistency = input.required<boolean>();
  readonly featureTexture = input.required<boolean>();
  readonly isAgent = input.required<boolean>();
  readonly isManager = input.required<boolean>();
  readonly order = input.required<Order>();
  readonly isOffline = input.required<boolean>();
  readonly minQuantity = input.required<number>();
  readonly quantityFieldIncrement = input.required<number>();
  readonly quantityFieldInput = input.required<boolean>();
  readonly showDescription = input.required<boolean>();
  readonly showOnlyPortionSize = input.required<boolean>();
  readonly showOnlyQuantity = input.required<boolean>();

  readonly descriptionChange = output<
    Partial<{
      description: string;
      quantity: number;
      portion_size: number;
      consistency: Consistency;
      texture: Texture;
      variants: OrderVariant[];
    }>
  >();
  readonly fetchProductSheet = output<{
    token: string;
  }>();
  readonly searchAutocomplete = output<{
    value: string;
  }>();

  readonly select = viewChildren<MatSelect>('select');

  consistencyLevels = CONSISTENCY_LEVELS;
  portionSizes: (0.5 | 1 | 1.5 | 2)[] = [0.5, 1, 1.5, 2];
  textureLevels = TEXTURE_LEVELS;
  currentQuantity = 1;
  matcher = new ShowImmediatelyErrorStateMatcher();

  public descForm = new FormGroup({
    description: new FormControl<string>(''),
    quantity: new FormControl<number>(1, { validators: [Validators.min(1)] }),
    portion_size: new FormControl<number>(1),
    consistency: new FormControl<null | Consistency>(null),
    texture: new FormControl<null | Texture>(null),
    variants: new FormControl<OrderVariant[]>([]),
  });

  ngOnChanges(changes: SimpleChanges): void {
    const quantityFieldIncrement = this.quantityFieldIncrement();
    if (
      'quantityFieldIncrement' in changes &&
      quantityFieldIncrement &&
      quantityFieldIncrement !== this.descForm.controls.quantity.value
    ) {
      this.descForm.controls.quantity.setValue(quantityFieldIncrement, {
        emitEvent: false,
      });
      this.descForm.controls.quantity.setValidators([
        Validators.min(quantityFieldIncrement),
        this.invalidQuantityValidator(),
      ]);
      this.descForm.controls.quantity.updateValueAndValidity();
    }
    if ('disabledPortionSizes' in changes && this.disabledPortionSizes()) {
      this.portionSizes = this.portionSizes.filter(
        (size) => !this.disabledPortionSizes().includes(size),
      );
    }
    const order = this.order();
    if ('order' in changes && order) {
      const consumer = this.consumer();
      const consumerValue = this.consumer();
      this.descForm.patchValue(
        {
          description: order?.description,
          quantity: order?.quantity ? order?.quantity : quantityFieldIncrement,
          portion_size:
            order?.portion_size ?? this.consumer()?.portion_size ?? 1,
          consistency:
            order?.consistency ??
            (isManagableOrderOrConsumer(consumer)
              ? consumer.consistency
              : null),
          texture:
            order?.texture ??
            (isManagableOrderOrConsumer(consumerValue)
              ? consumerValue.texture
              : null),
          variants: order?.variants || [],
        },
        { emitEvent: false },
      );
    }
  }

  ngOnInit(): void {
    this.descForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((v) => {
        if (this.descForm.valid) this.descriptionChange.emit(v);
      });
  }

  openProductSheetModal(): void {
    this.fetchProductSheet.emit({ token: this.order().productSheet });
    this.dialog.open(ProductSheetDialogComponent, {
      width: '800px',
      maxWidth: '100vw',
      maxHeight: '95vh',
    });
  }

  updateQuantity(action: 'increase' | 'decrease'): void {
    let newQuantity =
      this.descForm.controls.quantity.value +
      (action === 'increase'
        ? this.quantityFieldIncrement()
        : -1 * this.quantityFieldIncrement());
    newQuantity =
      newQuantity < this.minQuantity() ? this.minQuantity() : newQuantity;
    this.descForm.controls.quantity.setValue(newQuantity);
  }

  clearConsistency(event: MouseEvent): void {
    event.stopPropagation();
    this.descForm.patchValue({ consistency: null });
  }

  clearTexture(event: MouseEvent): void {
    event.stopPropagation();
    this.descForm.patchValue({ texture: null });
  }

  invalidQuantityValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value % this.quantityFieldIncrement() !== 0
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          { invalidQuantity: { value: control.value } }
        : null;
    };
  };

  searchAuto(value: string): void {
    this.searchAutocomplete.emit({ value });
    this.select().forEach((s) => s.close());
  }

  selectAuto(value: string): void {
    if (value) this.descForm.patchValue({ description: value });
  }
}
