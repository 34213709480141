<div class="mat-elevation-z1 wrapper">
  @if (consumer?.checkin) {
    <win-consumer-data-item
      class="item"
      [label]="'manage-accesses.table.headers.checkin' | transloco"
      [value]="
        (consumer?.checkin | date: 'shortDate' : undefined : lang()) +
        ' ' +
        (consumer?.['checkin_time'] ? consumer?.['checkin_time'] : '')
      "
    ></win-consumer-data-item>
  }
  @if (consumer?.checkout) {
    <win-consumer-data-item
      class="item"
      [label]="'manage-accesses.table.headers.checkout' | transloco"
      [value]="
        (consumer?.checkout | date: 'shortDate' : undefined : lang()) +
        ' ' +
        (consumer?.['checkout_time'] ? consumer?.['checkout_time'] : '')
      "
    ></win-consumer-data-item>
  }
  @if (consumer?.['birthday']) {
    <win-consumer-data-item
      class="item"
      [label]="'app.consumer-info.birthday' | transloco"
      [value]="consumer?.['birthday'] | date: 'mediumDate' : undefined : lang()"
    ></win-consumer-data-item>
  }
  @if (consumer?.type_name) {
    <win-consumer-data-item
      class="item"
      [label]="'app.consumer-info.type' | transloco"
      [value]="consumer?.type_name"
    ></win-consumer-data-item>
  }
  @if (dietsNames || dietsScheduledNames) {
    <win-consumer-data-item
      class="item"
      [label]="'app.consumer-info.diets' | transloco"
      [value]="dietsNames"
      [valueOverwritten]="dietsScheduledNames"
    ></win-consumer-data-item>
  }
  @if (consumerObject?.texture) {
    <win-consumer-data-item
      class="item"
      [label]="'app.consumer-info.texture' | transloco"
      [value]="
        'orders.descriptions.texture.' + consumerObject?.texture | transloco
      "
    ></win-consumer-data-item>
  }
  @if (consumerObject?.consistency) {
    <win-consumer-data-item
      class="item"
      [label]="'app.consumer-info.consistency' | transloco"
      [value]="
        'orders.descriptions.consistency.' + consumerObject?.consistency
          | transloco
      "
    ></win-consumer-data-item>
  }
  @if (allergies) {
    <win-consumer-data-item
      class="item"
      [label]="'app.consumer-info.allergies' | transloco"
      [value]="allergies"
    ></win-consumer-data-item>
  }
  @if (intolerances) {
    <win-consumer-data-item
      class="item"
      [label]="'app.consumer-info.intolerances' | transloco"
      [value]="intolerances"
    ></win-consumer-data-item>
  }
  @if (consumer?.portion_size && consumer?.portion_size !== 1) {
    <win-consumer-data-item
      class="item"
      [label]="'app.consumer-info.portion_size' | transloco"
      [value]="
        'orders.descriptions.portions.' + consumer?.portion_size * 10
          | transloco
      "
    ></win-consumer-data-item>
  }
  @if (consumer?.custom_data) {
    @for (item of consumer?.custom_data | keyvalue; track item) {
      <win-consumer-data-item
        class="item"
        [label]="item.key"
        [value]="item.value"
      ></win-consumer-data-item>
    }
  }
</div>
