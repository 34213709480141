import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of, switchMap, withLatestFrom } from 'rxjs';
import {
  catchError,
  expand,
  filter,
  finalize,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { OnlineMenusService } from 'src/app/shared/services/menus/online.menus.service';
import { OfflineModeService } from 'src/app/shared/services/offline-mode/offline-mode.service';
import { OnlineOrdersService } from 'src/app/shared/services/orders/online.orders.service';
import { OfflineModeErrorModalComponent } from 'src/app/global-components/header/offline-mode/offline-mode-error-modal/offline-mode-error-modal.component';
import {
  ManagableOrder,
  OfflineSetOrders,
  Order,
  SetOrders,
} from 'src/app/shared/models/orders';
import { UtilsService } from 'src/app/shared/services/utils.service';

import { logoutPartially } from './../authentication/authentication.actions';
import {
  fetchOfflineData,
  fetchOfflineOrders,
  setOfflineDataDownloading,
  setOfflineDate,
  setOfflineError,
  setOfflineModeValue,
  setOfflineOrdersSubmitting,
  submitOfflineOrders,
  toggleOfflineMode,
} from './offline-mode.actions';
import { globalFeature } from '../global/global.state';
import { offlineModeFeature } from './offline-mode.state';

@Injectable()
export class OfflineModeEffects {
  private readonly actions$ = inject(Actions);
  private readonly dialog = inject(MatDialog);
  private readonly onlineMenusService = inject(OnlineMenusService);
  private readonly onlineOrdersService = inject(OnlineOrdersService);
  private readonly offlineModeService = inject(OfflineModeService);
  private readonly store = inject(Store);
  private readonly utils = inject(UtilsService);

  toggleOfflineMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleOfflineMode),
      withLatestFrom(this.store.select(offlineModeFeature.selectValue)),
      switchMap(([{ payload }, isOffline]) => {
        if (isOffline) {
          return [
            setOfflineDate({ payload }),
            fetchOfflineData({ payload }),
            setOfflineDataDownloading({ payload: true }),
          ];
        }
        return [
          logoutPartially(),
          submitOfflineOrders(),
          setOfflineDate({ payload: null }),
        ];
      }),
    ),
  );

  fetchOfflineOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchOfflineOrders),
      withLatestFrom(this.store.select(offlineModeFeature.selectDate)),
      mergeMap(([{ consumer, menu }, date]) =>
        // FIXME: the API does not return any results as there is no current consumer set (since there is no "own" flag, it should still work though)
        this.onlineOrdersService
          .fetchOrders({
            date: date,
            menu: menu.identifier,
            consumer: consumer.id,
          })
          .pipe(
            mergeMap((order) => {
              this.offlineModeService.setOrders(
                consumer.uuid + '--' + menu.identifier,
                {
                  data: {
                    items: order,
                  },
                },
              );
              return EMPTY;
            }),
            catchError(() => []),
          ),
      ),
    ),
  );

  fetchOfflineData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchOfflineData),
      withLatestFrom(this.store.select(globalFeature.selectLanguage)),
      switchMap(([{ payload }, lang]) => {
        const consumers = [];
        const menuSources = {};
        const menuObjects = {};
        return this.offlineModeService.fetchConsumers().pipe(
          expand((res) =>
            res.next ? this.offlineModeService.fetchConsumers(res.next) : EMPTY,
          ),
          mergeMap((res) => res.results),
          mergeMap((consumer) => {
            const offset = payload.getTimezoneOffset();
            const date = new Date(payload.getTime() - offset * 60 * 1000)
              .toISOString()
              .split('T')[0];
            consumers.push(consumer);
            return this.onlineMenusService
              .fetchMenus({ consumer: consumer.id, date })
              .pipe(
                tap((menus) => {
                  menuObjects[consumer.uuid] = menus;
                }),
                mergeMap((menus) => menus.results),
                map((menu) => {
                  if (menu.has_orders || menu.list_orders.includes(date)) {
                    this.store.dispatch(fetchOfflineOrders({ consumer, menu }));
                  }
                  const params = {
                    response: 'html',
                    embed: true,
                    ordering: true,
                  };
                  if (menu.translations.includes(lang))
                    params['base_lang'] = lang;
                  if (menu.show_date) params['date'] = menu.date;
                  return { menu, params };
                }),
                filter(({ menu }) => !menuSources[menu.source]),
                mergeMap(({ menu, params }) => {
                  // FIXME: the wrong source is used when an existing order has diets different from the menu diets
                  return this.onlineOrdersService
                    .fetchMenuSource(menu.source, { ...params })
                    .pipe(
                      switchMap((menuSource) => {
                        menuSources[menu.source] = menuSource;
                        return [];
                      }),
                    );
                }),
              );
          }),
          catchError(() => [
            setOfflineDataDownloading({ payload: false }),
            setOfflineModeValue({ payload: false }),
          ]),
          finalize(() => {
            this.offlineModeService.setOfflineData('consumers', consumers);
            this.offlineModeService.setOfflineData('menus', menuObjects);
            this.offlineModeService.setOfflineData('menu-source', menuSources);
            this.offlineModeService.setOfflineOrdersData(
              this.offlineModeService.getOrders(),
            );
            return [
              this.store.dispatch(
                setOfflineDataDownloading({ payload: false }),
              ),
            ];
          }),
        );
      }),
    ),
  );

  submitOfflineOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitOfflineOrders),
      mergeMap(() => {
        const offlineData = this.offlineModeService.getOfflineOrdersData();
        const unsubmittedOrders: Record<string, OfflineSetOrders> = {};
        return of(offlineData).pipe(
          mergeMap((payload) => Object.entries(payload)),
          filter(([, value]) => value?.offline && value?.status === 'waiting'),
          tap(() => {
            this.store.dispatch(setOfflineOrdersSubmitting({ payload: true }));
          }),
          mergeMap(([key, orders]) => {
            const { url, data } = orders;
            return this.utils
              .post<SetOrders & Partial<ManagableOrder>, Order[]>(url, data)
              .pipe(
                switchMap(() => []),
                catchError(() => {
                  unsubmittedOrders[key] = orders;
                  this.store.dispatch(setOfflineError({ payload: true }));
                  this.dialog.open(OfflineModeErrorModalComponent);
                  return EMPTY;
                }),
              );
          }),
          finalize(() => {
            this.offlineModeService.setUnsubmittedOrders(unsubmittedOrders);
            this.offlineModeService.clearOrders();
            return [
              this.store.dispatch(
                setOfflineOrdersSubmitting({ payload: false }),
              ),
            ];
          }),
        );
      }),
    ),
  );
}
