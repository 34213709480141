<mat-card
  appearance="outlined"
  [class]="
    format() === 'html/pdf'
      ? 'report-card success-gradient'
      : 'report-card success-green-gradient'
  "
>
  <div class="download-report-card">
    @if (name()) {
      <div class="report-title mat-body-strong">
        {{ 'reports.name.' + name() | transloco }}
      </div>
    }
    @if (innerHTML()) {
      <div class="report-title mat-body-strong" [innerHTML]="innerHTML()"></div>
    }
    @if (totalCount()) {
      <div class="mat-caption">
        ({{ nameItems() | transloco: { count: totalCount() } }})
      </div>
    }
  </div>

  <div class="button-container">
    @if (format() === 'html/pdf' && !hideDigital()) {
      <button
        mat-button
        class="web-view-btn"
        aria-label="Open report"
        (click)="openReport.emit()"
      >
        <mat-icon>public</mat-icon>
        {{ 'reports.card.view' | transloco }}
        ({{ 'reports.card.web' | transloco }})
      </button>
    }
    @if (format() === 'excel') {
      <button
        mat-button
        class="download-report-btn"
        aria-label="Download report (csv)"
        [disabled]="loading()"
        (click)="downloadReport.emit('csv')"
      >
        @if (loading()) {
          <span class="download-spinner">
            <mat-spinner
              class="spinner"
              color="warn"
              mode="indeterminate"
              [diameter]="24"
            ></mat-spinner>
          </span>
        } @else {
          <mat-icon class="report-download-button">get_app</mat-icon>
        }
        {{ 'reports.card.download' | transloco }}
        ({{ 'reports.card.formats.csv' | transloco }})
      </button>
    }
    <button
      mat-button
      class="download-report-btn"
      aria-label="Download report"
      [disabled]="loading()"
      (click)="downloadReport.emit(undefined)"
    >
      @if (loading()) {
        <span class="download-spinner">
          <mat-spinner
            class="spinner"
            color="warn"
            mode="indeterminate"
            [diameter]="24"
          ></mat-spinner>
        </span>
      } @else {
        <mat-icon class="report-download-button">get_app</mat-icon>
      }
      {{ 'reports.card.download' | transloco }}
      ({{
        'reports.card.formats.' + (format() === 'excel' ? format() : 'pdf')
          | transloco
      }})
    </button>
  </div>
</mat-card>
