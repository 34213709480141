import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoPipe } from '@jsverse/transloco';

export interface SimpleDialogData {
  title?: string;
  text?: string;
  link?: string;
  linkText?: string;
  cancelable?: boolean;
  warn?: boolean;
  confirmIcon?: string;
  confirmText?: string;
  cancelText?: string;
}

@Component({
  selector: 'win-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrl: './simple-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatDialogModule, MatIconModule, TranslocoPipe],
})
export class SimpleDialogComponent {
  data = inject<SimpleDialogData>(MAT_DIALOG_DATA);
}
