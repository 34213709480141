<header class="mat-h2">{{ order()?.item }}</header>
<div class="variants">
  @for (variant of order().all_variants; track variant) {
    <div
      #element
      [attr.data-variant]="variant.variant_id"
      [attr.data-item]="variant.item"
      [attr.data-item-base]="variant.item_baselang"
      [attr.data-price]="variant.price"
      [class.selected]="selectedVariantIds.includes(variant.variant_id)"
      class="variant"
      (click)="handleSelectedVariant(element)"
    >
      <span class="variant-name">{{ variant.item }}</span>
      @if (variant.price) {
        <span class="variant-price">{{ variant.price | number: '1.2-2' }}</span>
      }
    </div>
  }
</div>
