import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { logout } from './redux/authentication/authentication.actions';
import { FooterComponent } from './global-components/footer/footer.component';
import { HeaderComponent } from './global-components/header/header.component';
import { PreFooterComponent } from './global-components/pre-footer/pre-footer.component';
import { filter, take, withLatestFrom } from 'rxjs';
import { selectUrl } from './redux/router/router.selectors';
import { LANGUAGES } from './shared/constants/languages';
import { setGlobalLanguage } from './redux/global/global.actions';
import { ErrorBannerComponent } from './global-components/error-banner/error-banner.component';
import { globalFeature } from './redux/global/global.state';
import { offlineModeFeature } from './redux/offline-mode/offline-mode.state';
import { usersFeature } from './redux/user/user.state';
import { authenticationFeature } from './redux/authentication/authentication.state';
import { ReportActions } from './redux/reports/report.actions';

@Component({
  selector: 'win-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    AsyncPipe,
    CommonModule,
    ErrorBannerComponent,
    FooterComponent,
    HeaderComponent,
    PreFooterComponent,
    RouterOutlet,
  ],
})
export class AppComponent implements OnInit {
  private readonly store = inject(Store);

  emailVerified$ = this.store.pipe(select(usersFeature.selectEmailVerified));
  globalError$ = this.store.select(globalFeature.selectGlobalError);
  globalLanguage$ = this.store.pipe(select(globalFeature.selectLanguage));
  initialUrl$ = this.store.pipe(select(selectUrl));
  isLoggedIn$ = this.store.pipe(select(authenticationFeature.selectIsLogged));
  isAgent$ = this.store.pipe(select(usersFeature.selectIsAgent));
  isManager$ = this.store.pipe(select(usersFeature.selectIsManager));
  isOffline$ = this.store.pipe(select(offlineModeFeature.selectValue));
  isUnauth$ = this.store.pipe(select(authenticationFeature.selectIsSimpleAuth));
  logo$ = this.store.pipe(select(usersFeature.selectLogo));
  offlineOrderCount$ = this.store.select(offlineModeFeature.selectOrderCount);
  showOfflineMode$ = this.store.select(
    offlineModeFeature.selectAllowOfflineMode,
  );
  simpleConsumer$ = this.store.pipe(select(usersFeature.selectSimpleConsumer));
  terminal$ = this.store.pipe(select(usersFeature.selectTerminal));
  userFullName$ = this.store.pipe(select(usersFeature.selectUserFullName));

  ngOnInit(): void {
    this.initialUrl$
      .pipe(
        withLatestFrom(this.isLoggedIn$),
        filter(([url, loggedIn]) => loggedIn && url !== undefined),
        take(1),
      )
      .subscribe(([url]) => {
        if (url.startsWith('/redirect') === false) {
          this.store.dispatch(ReportActions.fetchReports());
        }
      });
  }

  languageChange(value: keyof typeof LANGUAGES): void {
    this.store.dispatch(setGlobalLanguage({ payload: value }));
  }

  exitCompletely(): void {
    this.store.dispatch(logout());
  }
}
